import axios from "axios";
import { API } from "../../Assets/API";
import {
  GET_TRANSACTIONS,
  CLEAR_MESSAGE,
  FAILED,
  SET_RATES,
  SET_HASHID,
  SET_GETWAY,
} from "./transactionType";

// ======================== FETCH TRANSACTION RATES ===========================
export const getRates = (rates) => {
  return{
    type: SET_RATES,
    data: rates
  }
};

// ======================== FETCH TRANSACTION RATES ===========================
export const getGateWay = (gateway) => {
  return{
    type: SET_GETWAY,
    data: gateway
  }
};

// ============================= USER TRANSACTIONS =============================
export const getTransaction = () => async (dispatch) => {
  // 2. clear previous error message
  dispatch({ type: CLEAR_MESSAGE });

  // 3. call api
  try {
    // 4. if login successful save token in local storage and setup auth token for axios headers
    const res = await axios.get(API.getUserTransaction);
    dispatch({ type: GET_TRANSACTIONS, data: res.data.data });
    return true;
  } catch (err) {
    // 5. if login failed then setup error message
    dispatch({ type: FAILED, message: "Something went wrong." });
    return false;
  }
};


// ============================= SET TRANSACTION HASH =============================
export const setHash = (hash) => {
  return{
    type: SET_HASHID,
    hash: hash
  }
}