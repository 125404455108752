import UserData from './UserData'
import style from './UserTable.module.scss'

function UserTable({ data }) {
  return (
    <>
      <div className={style.head}>
        <div className={style.id}>Id</div>
        <div className={style.name}>Name</div>
        <div className={style.email}>Email</div>
        <div className={style.phone}>Phone</div>
        <div className={style.status}>Status</div>
        <div className={style.role}>Role</div>
      </div>
      
      {data && data.map((d) => (
        <UserData key={Math.random()} info={d} />
      ))}
    </>
  )
}

export default UserTable