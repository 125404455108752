import { useState } from "react";
import { Helmet } from "react-helmet";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// styles & images
import style from "./Contact.module.scss";
import handshake from "../../../Assets/Contact/handshake.png";
// components
import AuthGuard from "../../../components/AuthGuard/AuthGuard";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import useAnalyticsEventTracker from "../../../utils/useAnalyticsEventTracker";
import axios from "axios";
import { API } from "../../../Assets/API";
import toast from "react-hot-toast";

function Contact() {
	const [loading, setLoading] = useState(false);
	const gaEventTracker = useAnalyticsEventTracker("Contact us");

	// initial values
	const initVals = {
		name: "",
		email: "",
		subject: "",
		message: "",
	};

	// validation schema
	const formSchema = Yup.object().shape({
		email: Yup.string().required("Email is required!"),
		name: Yup.string().required("Name is required!"),
		subject: Yup.string()
			.required("Subject is required!")
			.min(10, "Subject should be more than 10 character long."),
		message: Yup.string()
			.required("Message is required!")
			.min(10, "Message should be more than 10 character long."),
	});

	const formSubmit = (values) => {
		setLoading(true);
		let data = new FormData();
		data.append("name", values.name);
		data.append("email", values.email);
		data.append("subject", values.subject);
		data.append("message", values.message);

		axios
			.post(API.sendEmail, data)
			.then(() => {
				gaEventTracker("email");
				toast.success("Thanks for your message.");
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				toast.error("Something went wrong. Try again !");
			});
	};

	return (
		<>
			<Helmet>
				<title>Support | Dollar Buy Sell Corner</title>
				{/* <meta name="title" content="Support | Dollar Buy Sell Corner" />
				<meta
					name="description"
					content="Dollar Buy Sell BD, bKash to perfect money exchange, Perfect Money dollar buy sell, Perfect Money to bKash, Perfect Money Exchanger, Bitcoin Dollar Buy Sell BD, Online Dollar Buy Sell BD, Dollar Buy Sell, USD Buy Sell BD"
				/>
				<meta
					name="keywords"
					content="dollar buy sell, dollar buy sell bd, buy sell dollar, usd to bd, bd dollar buy sell, dollar buy sell bangladesh, usd buy sell, dollar buy and sell, skrill dollar buy sell bd, buy sell in bd, buy sell bd,buy sell exchange, buy sell and exchange, dollar buy and sell bd, online dollar buy sell bd, trusted dollar buy sell in bangladesh"
				/> */}
			</Helmet>

			<Navbar />
			<AuthGuard logged="no" roleActive={false}></AuthGuard>
			<div className={style.contact}>
				<div className={style.contact__left}>
					<div className={style.text}>
						<h1>
							<div>We love to</div>
							<div>hear from you</div>
						</h1>
						<p>
							Send mail us at{" "}
							<a href="mailto:support@dollarbuysellcorner.com" target="_blank">
								support@dollarbuysellcorner.com
							</a>
						</p>
					</div>
					<div className={style.image}>
						<img src={handshake} alt="handshake" />
					</div>
				</div>

				<div className={style.contact__right}>
					<Formik
						initialValues={initVals}
						validationSchema={formSchema}
						onSubmit={(values) => formSubmit(values)}
					>
						{({ errors, touched }) => (
							<Form>
								{/* ============ Nmae Fields ============ */}
								<div className={style.inputGroup}>
									<label htmlFor="name">Name</label>
									<Field
										name="name"
										placeholder="Enter your name"
										type="text"
										className={
											errors.name && touched.name ? style.haveerror : ``
										}
									/>
									<small>
										<ErrorMessage name="name" />
									</small>
								</div>
								{/* ============ Email Fields ============ */}
								<div className={style.inputGroup}>
									<label htmlFor="email">Email</label>
									<Field
										name="email"
										placeholder="Enter your email"
										type="email"
										className={
											errors.email && touched.email ? style.haveerror : ``
										}
									/>
									<small>
										<ErrorMessage name="email" />
									</small>
								</div>
								{/* ============ Subject Fields ============ */}
								<div className={style.inputGroup}>
									<label htmlFor="subject">Subject</label>
									<Field
										name="subject"
										placeholder="Enter message subject"
										type="text"
										className={
											errors.subject && touched.subject ? style.haveerror : ``
										}
									/>
									<small>
										<ErrorMessage name="subject" />
									</small>
								</div>
								{/* ============ Subject Fields ============ */}
								<div className={style.inputGroup}>
									<label htmlFor="message">Message</label>
									<Field
										as="textarea"
										rows="10"
										name="message"
										placeholder="Enter your message"
										type="text"
										className={
											errors.message && touched.message ? style.haveerror : ``
										}
									/>
									<small>
										<ErrorMessage name="message" />
									</small>
								</div>

								{/* ============ Submit Button ============ */}
								{loading ? (
									<button type="submit" disabled>
										Loading ...
									</button>
								) : (
									<button type="submit">Send</button>
								)}
							</Form>
						)}
					</Formik>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Contact;
