import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
// Assets
import dataImage from "../../../Assets/Exchange/data.png";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { API } from "../../../Assets/API";
import style from "./Users.module.scss";
// Components
import Footer from "../../../components/Footer/Footer";
import UserTable from "../../../components/UserTable/UserTable";
import TableDataLoading from "../../../components/TransactionTableForAdmin/TableDataLoading";
import Navbar from "../../../components/Navbar/Navbar";
import { useDispatch } from "react-redux";
import { checAuthenticated, logoutUser } from "../../../redux/user/userAction";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../../../components/AuthGuard/AuthGuard";

function Users() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// handle pagination
	const handlePageClick = (event) => {
		setCurrentPage(event.selected + 1);
	};

	// fetch data
	const fetchData = useCallback(
		(pageNumber) => {
			if (dispatch(checAuthenticated)) {
				setLoading(true);
				axios
					.get(`${API.userList}?page=${pageNumber}`)
					.then((res) => {
						setLastPage(res.data.data.last_page);
						setData(res.data.data.data);
						setLoading(false);
					})
					.catch(() => {
						setLoading(false);
					});
			} else {
				setLoading(false);
				dispatch(logoutUser);
				navigate("/login");
			}
		},
		[dispatch, navigate]
	);

	// load data at the beginning & when page number is changed
	useEffect(() => {
		fetchData(currentPage);
	}, [currentPage, fetchData]);

	return (
		<>
			<Navbar />
			<AuthGuard logged="no" roleActive={true}>
				<div className={style.users}>
					{/* ============= primary heading ============= */}
					<h1>
						User <span>List</span>
					</h1>
					<p>Check the latest update of your information.</p>

					{/* ============= user table ============= */}
					<div className={style.tableWrapper}>
						{loading ? (
							<div className={style.table}>
								{Array(12)
									.fill("")
									.map(() => (
										<TableDataLoading key={Math.random()} />
									))}
							</div>
						) : data.length > 0 ? (
							<div className={style.table}>
								<UserTable data={data} />
							</div>
						) : (
							<div className={style.emptyBlock}>
								<img src={dataImage} alt="Data error" />
								<div className={style.text}>
									<h2>Empty Data Table</h2>
									<p>
										User Table is empty. It could be network issue. Please,
										reload the page and and try again.
									</p>
								</div>
							</div>
						)}
					</div>

					{/* ============= Pagination ============= */}
					{data.length > 0 ? (
						<ReactPaginate
							breakLabel={window.innerWidth >= 700 ? "..." : ""}
							nextLabel={<BsChevronRight />}
							previousLabel={<BsChevronLeft />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={window.innerWidth >= 700 ? 5 : 2}
							pageCount={lastPage}
							renderOnZeroPageCount={null}
							containerClassName={style.paginationBttns}
							previousLinkClassName={style.previousBtn}
							nextLinkClassName={style.nextsBtn}
							disabledClassName={style.paginationDisabled}
							activeClassName={style.paginationActive}
						/>
					) : null}
				</div>
			</AuthGuard>
			<Footer />
		</>
	);
}

export default Users;
