import axios from "axios";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import style from "./MarqueeText.module.scss";
import { API } from "../../Assets/API";
import { BsFillCircleFill } from "react-icons/bs";

function MarqueeText() {
	const [notices, setNotices] = useState([]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axios
			.get(API.getNotice)
			.then((res) => {
				setNotices(res.data.data);
				setLoading(false);
			})
			.catch(() => {
				setError("Network error. Please reload and try again.");
				setLoading(false);
			});
	}, []);

	return (
		<>
			{!loading ? (
				<div className={style.marqueeText}>
					<Marquee speed={50} gradient={false} className={style.marguee}>
						{notices && notices.length > 0 ? (
							notices.map((notice) => (
								<div key={notice.id} className={style.text}>
									{notice.notice_text}{" "}
									<span className={style.divider}>
										<BsFillCircleFill />
									</span>
								</div>
							))
						) : (
							<span>{error}</span>
						)}
					</Marquee>
				</div>
			) : null}
		</>
	);
}

export default MarqueeText;
