import { useEffect, useState } from "react";
import style from "./TransactionTableForAdmin.module.scss";
import { FiLink } from "react-icons/fi";
// Components
import OptionButton from "../OptionButton/OptionButton";
import TransationDetail from "./TransationDetail";

const options = [
  //   { id: 0, status: "Incomplete" },
  { id: 6, status: "Completed" },
  { id: 1, status: "Pending" },
  { id: 2, status: "Processing" },
  { id: 3, status: "Rejected" },
  { id: 4, status: "Canceled" },
  { id: 5, status: "Hold" },
];

function TableData({ info, pageRef }) {
  const [currentStatus, setCurrentStatus] = useState({});
  const [open, setOpen] = useState(false);


  // select transaction status
  useEffect(() => {
    let found = false;

    options.forEach(s => {
      if (s.status === info.status) {
        setCurrentStatus(s);
        found = true;
      }
    });

    if(!found){
      setCurrentStatus({id: 0, status: "Incomplete"});
    }
  }, [info.status]);

  return (
    <>
      <div className={style.data}>
        <div className={style.id} onClick={() => setOpen(true)}><FiLink/> {info.id}</div>
        <div className={style.username} >{info.user_name}</div>
        <div className={style.sent} >{info.sent}</div>
        <div className={style.received}>{info.received}</div>
        <div className={style.amount}>
          {info.sent_unit === `\\u0024` ? "$" : "৳"} {info.amount}
        </div>
        <div className={style.amount}>
          {info.received_unit === `\\u0024` ? "$" : "৳"} {info.received_amount}
          </div>
        <div className={style.status}>
          <OptionButton
            id={info.id}
            status={currentStatus}
            setStatus={setCurrentStatus}
            options={options}
            pageRef={pageRef}
          />
        </div>
      </div>

      {open ? <TransationDetail data={info} setOpen={setOpen} /> : null}
    </>
  );
}

export default TableData;
