import style from "./NidUploadOption.module.scss";
import { useSelector } from "react-redux";
import { AiFillWarning, AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";

function NidUploadOption({ setChange }) {
  const user = useSelector((state) => state.user);

  return (
    <>
    {
      user.currentUser && user.currentUser.status === "incomplete"
      ? user.currentUser && user.currentUser.nid_number && user.currentUser.nid_number.length !== 0
        ? <>
            <div className={style.notify}>
              <h4>
                <div><AiFillWarning /></div>
                <div>Please wait for verification</div>
              </h4>
              <p>
                You recently uploaded NID numbers and images. You can resubmit
                your NID information if you feel you submitted wrong
                informations.
              </p>
              <button onClick={() => setChange(true)}>Upload NID</button>
            </div>
          </>
        : <>
            <div className={style.notify}>
              <h4>
                <div>
                  <AiFillWarning />
                </div>
                <div>Verify your account</div>
              </h4>
              <p>
                You are not verify your account yet. Please give required data
                and verify your account to start transaction.
              </p>
              <button onClick={() => setChange(true)}>Upload NID</button>
            </div>
          </>
      : <>
          <div className={style.varified}>
            <h4>
              <div><AiFillCheckCircle /></div>
              <div>Account Verified</div>
            </h4>
            <ul>
              <li><span><AiOutlineCheckCircle /></span> Full Name</li>
              <li><span><AiOutlineCheckCircle /></span>Email</li>
              <li><span><AiOutlineCheckCircle /></span>Phone Number</li>
              <li><span><AiOutlineCheckCircle /></span>NID Number</li>
              <li><span><AiOutlineCheckCircle /></span>NID Front Side</li>
              <li><span><AiOutlineCheckCircle /></span>NID Back Side</li>
            </ul>
          </div>
        </>
    }
    </>
  );
}

export default NidUploadOption;
