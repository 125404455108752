import Slider from "react-slick";
// styles
import styles from "./WelcomeSlide.module.scss";
// images
import image1 from "../../Assets/Signin/image1.png";
import image2 from "../../Assets/Signin/image2.png";
import image3 from "../../Assets/Signin/image3.png";

function WelcomeSlide() {
  // slider configurations
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };

  return (
    <div className={styles.welcomeSlides}>
      <Slider {...settings}>
        <div className={styles.slide}>
          <img src={image1} alt="" />
          <h2>Exchange Money Easier</h2>
          <p>
            You can easily transfer your money from any of your accounts to
            another account through our site.
          </p>
        </div>
        <div className={styles.slide}>
          <img src={image2} alt="" />
          <h2>Have the Exciting Rates</h2>
          <p>
            We give you the correct & exciting currency rates that you may like.
          </p>
        </div>
        <div className={styles.slide}>
          <img src={image3} alt="" />
          <h2>Manage All the Transactions</h2>
          <p>
            Track and record your every transaction easily to get control over
            your balance.
          </p>
        </div>
      </Slider>
    </div>
  );
}

export default WelcomeSlide;
