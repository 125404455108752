import { useState } from "react";
import style from "./PasswordResetForm.module.scss";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { API } from "../../Assets/API";
import toast from "react-hot-toast";

function PasswordResetForm() {
  const [loading, setLoading] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  // INITIAL VALUES
  const initVals = {
    prevPass: ``,
    newPass: ``,
    confirmPass: ``,
  };

  // VALIDATION SCHEMA
  const formSchema = Yup.object().shape({
    prevPass: Yup.string().required("Previous password is required!"),
    newPass: Yup.string().required("New Password is required!").min(6, "Name must be 6 or more character long."),
    confirmPass: Yup.string().required("Retype password is required!").oneOf([Yup.ref("newPass"), null], "Passwords should be matched"),
  });

  //   ON SUBMIT HANDLER
  const updatePassword = (values) => {
    setLoading(true);
    setErrorMessage({});

    let data = {
      old_password: values.prevPass,
      new_password: values.newPass,
    }

    axios.patch(API.changePassword, data)
    .then(() => {
      setLoading(false);
      toast.success("Password is changed successfully.");
    })
    .catch((err) => {
      setLoading(false);
      console.log(err.response.data);
      setErrorMessage(err.response.data.errors);
    });
  };

  return (
    <div className={style.passwordForm}>
      <Formik
        initialValues={initVals}
        validationSchema={formSchema}
        onSubmit={(values) => updatePassword(values)}
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form>
            {/* ============ Previous Password Fields ============ */}
            <div className={style.inputGroup}>
              <label htmlFor="prevPass">Previous Password</label>
              <Field
                name="prevPass"
                placeholder="Enter your previous password"
                type={!showPrev ? "password" : "text"}
                className={ ( errorMessage && errorMessage.old_password ) || ( errors.prevPass && touched.prevPass ) ? style.haveerror : ``}
              />
              <div className={style.eye}>
                {showPrev ? (
                  <span onClick={() => setShowPrev(false)}>
                    <AiOutlineEye />
                  </span>
                ) : (
                  <span onClick={() => setShowPrev(true)}>
                    <AiOutlineEyeInvisible />
                  </span>
                )}
              </div>
              <small>
                <ErrorMessage name="prevPass" />
                {errorMessage && errorMessage.old_password && errorMessage.old_password[0]}
              </small>
            </div>

            {/* ============ New Password Fields ============ */}
            <div className={style.inputGroup}>
              <label htmlFor="newPass">New Password</label>
              <Field
                name="newPass"
                placeholder="Enter a new password"
                type={!showNew ? "password" : "text"}
                className={
                  errors.newPass && touched.newPass ? style.haveerror : ``
                }
              />
              <div className={style.eye}>
                {showNew ? (
                  <span onClick={() => setShowNew(false)}>
                    <AiOutlineEye />
                  </span>
                ) : (
                  <span onClick={() => setShowNew(true)}>
                    <AiOutlineEyeInvisible />
                  </span>
                )}
              </div>
              <small>
                <ErrorMessage name="newPass" />
              </small>
            </div>

            {/* ============ Confirm Password Fields ============ */}
            <div className={style.inputGroup}>
              <label htmlFor="confirmPass">Confirm Password</label>
              <Field
                name="confirmPass"
                placeholder="Enter a new password"
                type={!showConfirm ? "password" : "text"}
                className={
                  errors.confirmPass && touched.confirmPass
                    ? style.haveerror
                    : ``
                }
              />
              <div className={style.eye}>
                {showConfirm ? (
                  <span onClick={() => setShowConfirm(false)}>
                    <AiOutlineEye />
                  </span>
                ) : (
                  <span onClick={() => setShowConfirm(true)}>
                    <AiOutlineEyeInvisible />
                  </span>
                )}
              </div>
              <small>
                <ErrorMessage name="confirmPass" />
              </small>
            </div>

            {/* Error Message */}
            <small className={style.errorMessage}></small>

            {/* ============ Submit Button ============ */}
            {loading ? (
              <button type="submit" disabled>
                Loading ...
              </button>
            ) : (
              <button type="submit">Update Password</button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PasswordResetForm;
