import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import style from "./Privacy.module.scss";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Privacy() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Helmet>
				<title>Dollar Buy Sell Corner</title>
				{/* <meta name="title" content="Privay & Policy | Dollar Buy Sell Corner" />
				<meta
					name="description"
					content="Trusted Dollar Buy Sell Site Dollar Buy Sell Corner. You can buy sell and exchange all kinds of dollars online from our site from WebMoney to Perfect Money to PayPal to Payeer to Skrill."
				/>
				<meta
					name="keywords"
					content="dollar buy sell, dollar exchange, dollar exchange bd, dollar buy sell bd, dollar buy sell bangladesh, dollar sell bd, skrill dollar buy sell bd, usd buy sell, dollar buy sell website, dollar buy sell in bangladesh"
				/> */}
			</Helmet>

			<Navbar />
			<div className={style.privacy}>
				<div className={style.privacy__container}>
					<h1>
						Privacy <span>& Policies</span>
					</h1>
					<p className={style.headingText}>
						We value your privacy so we are committed to keeping your personal
						and business information safe. We don't feel good about how privacy
						and tracking is often handled in today's world, so we only ask for
						the bare minimum.
					</p>
					<p className={style.headingTextB}>
						আমরা আপনার গোপনীয়তাকে মূল্য দিই তাই আমরা আপনার ব্যক্তিগত এবং
						ব্যবসার তথ্য সুরক্ষিত রাখতে প্রতিশ্রুতিবদ্ধ। আজকের বিশ্বে প্রায়শই
						গোপনীয়তা এবং ট্র্যাকিং কীভাবে পরিচালনা করা হয় সে সম্পর্কে আমরা ভাল
						বোধ করি না, তাই আমরা কেবলমাত্র সর্বনিম্ন জন্য জিজ্ঞাসা করি।
					</p>
					{/* Block - General */}
					<div className={style.block}>
						<h2>1 : General</h2>
						<div className={style.block__sub}>
							<h3>1.1 : Timeline</h3>
							<p>
								We will be offering our services from 10:00 AM to 04:00 AM and
								there will be a 30 minute break for prayers during the prayer
								times. We try to complete your order within 1 to 60 minutes of
								placing the order. If we are unable to complete the order within
								that time due to technical issues, we will notify you within the
								specific time.{" "}
								<span>
									- আমরা সকাল 10:00 AM থেকে 04:00 AM পর্যন্ত আমাদের পরিষেবা
									প্রদান করব এবং প্রার্থনার সময় প্রার্থনার জন্য 30 মিনিটের
									বিরতি থাকবে৷ আমরা অর্ডার দেওয়ার 1 থেকে 60 মিনিটের মধ্যে আপনার
									অর্ডার সম্পূর্ণ করার চেষ্টা করি। প্রযুক্তিগত সমস্যার কারণে যদি
									আমরা সেই সময়ের মধ্যে অর্ডারটি সম্পূর্ণ করতে না পারি, তাহলে
									আমরা নির্দিষ্ট সময়ের মধ্যে আপনাকে অবহিত করব।
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>1.2 : Transaction policy</h3>
							<p>
								If we have reason to believe that a transaction could be related
								to money laundering or another type of illegal conduct, also due
								to technical issues and difficulties we have the authority to
								refuse any transaction at any time.{" "}
								<span>
									- যদি আমাদের বিশ্বাস করার কারণ থাকে যে একটি লেনদেন মানি
									লন্ডারিং বা অন্য ধরনের অবৈধ আচরণের সাথে সম্পর্কিত হতে পারে,
									এছাড়াও প্রযুক্তিগত সমস্যা এবং অসুবিধার কারণে আমাদের কাছে যে
									কোনো সময় কোনো লেনদেন প্রত্যাখ্যান করার ক্ষমতা আছে
								</span>
							</p>
						</div>
					</div>

					{/* Block - Information */}
					<div className={style.block}>
						<h2>2 : Information</h2>
						<div className={style.block__sub}>
							<h3>2.1 : Personal Information</h3>
							<p>
								When you use our sign in, sign up form, We collect certain
								personal information from you, including your name, phone
								number, email address and more. Also when you exchange, buy or
								sell money we collect your account number to complete our basic
								operations. We refer to this information as personal
								information.{" "}
								<span>
									- আপনি যখন আমাদের সাইন ইন, সাইন আপ ফর্ম ব্যবহার করেন, তখন আমরা
									আপনার নাম, ফোন নম্বর, ইমেল ঠিকানা এবং আরও অনেক কিছু সহ আপনার
									কাছ থেকে কিছু ব্যক্তিগত তথ্য সংগ্রহ করি। এছাড়াও আপনি যখন অর্থ
									বিনিময়, ক্রয় বা বিক্রি করেন তখন আমরা আমাদের মৌলিক
									ক্রিয়াকলাপগুলি সম্পূর্ণ করার জন্য আপনার অ্যাকাউন্ট নম্বর
									সংগ্রহ করি। আমরা এই তথ্যটিকে ব্যক্তিগত তথ্য হিসাবে উল্লেখ করি।
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>2.2 : When do we collect your personal information</h3>
							<p>
								To give better and secured services we need to collect
								information everytime you fill up forms on our site. To be more
								specific they are at the time of login, registration, profile
								verification (NID) information and buying or selling money.{" "}
								<span>
									- আরও ভাল এবং সুরক্ষিত পরিষেবা দেওয়ার জন্য আপনি যখনই আমাদের
									সাইটে ফর্মগুলি পূরণ করবেন তখন আমাদের তথ্য সংগ্রহ করতে হবে। আরো
									সুনির্দিষ্ট হতে তারা লগইন, রেজিস্ট্রেশন, প্রোফাইল ভেরিফিকেশন
									(NID) তথ্য এবং অর্থ কেনা বা বিক্রির সময়।
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>2.3 : How long we keep your information</h3>
							<p>
								It is our duty to give you security so that we stored all your
								informations until you ask us to delete it.{" "}
								<span>
									- আপনাকে নিরাপত্তা দেওয়া আমাদের দায়িত্ব যাতে আপনি আমাদেরকে
									এটি মুছে দিতে না বলা পর্যন্ত আমরা আপনার সমস্ত তথ্য সংরক্ষণ করি
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>2.4 : With whom we share your personal information</h3>
							<p>
								Except as particularly needed by a duly executed court order or
								other legal act, the information requested here will be kept
								private and not disclosed to other parties for any reason. No
								third parties will receive this information for marketing or any
								other purposes.{" "}
								<span>
									- একটি যথাযথভাবে কার্যকর করা আদালতের আদেশ বা অন্যান্য আইনী
									আইনের দ্বারা বিশেষভাবে প্রয়োজন ব্যতীত, এখানে অনুরোধ করা তথ্য
									গোপন রাখা হবে এবং কোনো কারণে অন্য পক্ষের কাছে প্রকাশ করা হবে
									না। কোন তৃতীয় পক্ষ বিপণন বা অন্য কোন উদ্দেশ্যে এই তথ্য পাবেন
									না।
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>2.5 : Providing wrong information</h3>
							<p>
								Providing false verification documents or contact details will
								be deemed as a misuse of our terms of service and you may be the
								subject to a criminal investigation.{" "}
								<span>
									- মিথ্যা যাচাইকরণ নথি বা যোগাযোগের বিবরণ প্রদান করা আমাদের
									পরিষেবার শর্তাবলীর অপব্যবহার হিসাবে বিবেচিত হবে এবং আপনি একটি
									ফৌজদারি তদন্তের বিষয় হতে পারেন।
								</span>
							</p>
						</div>
					</div>

					{/* Block  - Your rights*/}
					<div className={style.block}>
						<h2>3 : Your rights</h2>
						<div className={style.block__sub}>
							<h3>3.1 : Change Information</h3>
							<p>
								You can change your information whenever you want from the
								profile section. Here you can only update your basic information
								and password. Mention that, you can't update or change your
								transaction after it is completed.{" "}
								<span>
									- আপনি প্রোফাইল বিভাগ থেকে যখনই চান আপনার তথ্য পরিবর্তন করতে
									পারেন। এখানে আপনি শুধুমাত্র আপনার মৌলিক তথ্য এবং পাসওয়ার্ড
									আপডেট করতে পারবেন। উল্লেখ্য যে, আপনার লেনদেন সম্পূর্ণ হওয়ার
									পরে আপনি আপডেট বা পরিবর্তন করতে পারবেন না।
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>3.2 : Delete information</h3>
							<p>
								If you want to delete your information from our database you can
								contact us through email, whatsapp or by giving a message in
								messenger. We will remove your account and personal information
								from our database. Mention that, we can't delete your
								transaction history due to our security and legal purpose.{" "}
								<span>
									- আপনি যদি আমাদের ডাটাবেস থেকে আপনার তথ্য মুছে ফেলতে চান তাহলে
									আপনি ইমেল, হোয়াটসঅ্যাপ বা মেসেঞ্জারে একটি বার্তা দিয়ে আমাদের
									সাথে যোগাযোগ করতে পারেন। আমরা আমাদের ডাটাবেস থেকে আপনার
									অ্যাকাউন্ট এবং ব্যক্তিগত তথ্য মুছে ফেলব। উল্লেখ্য যে, আমরা
									আমাদের নিরাপত্তা এবং আইনি উদ্দেশ্যের কারণে আপনার লেনদেনের
									ইতিহাস মুছে ফেলতে পারি না।
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>3.3 : Objection </h3>
							<p>
								You can always use your right of objection and refuse the
								processing of your personal data.{" "}
								<span>
									- আপনি সর্বদা আপনার আপত্তির অধিকার ব্যবহার করতে পারেন এবং
									আপনার ব্যক্তিগত ডেটা প্রক্রিয়াকরণ প্রত্যাখ্যান করতে পারেন।
								</span>
							</p>
						</div>
					</div>

					{/* Block  - Your rights*/}
					<div className={style.block}>
						<h2>4 : Anti money laundering</h2>
						<div className={style.block__sub}>
							<h3>4.1 : We are against anti-money laundering</h3>
							<p>
								Money laundering is the process of turning funds from illicit
								activities into funds or assets that appear to be lawful so that
								the funds' illegal source cannot be identified.Fastechanger.com
								views money laundering as a serious criminal offense, and as
								such, complies with requirements intended to prevent money
								laundering.{" "}
								<span>
									- মানি লন্ডারিং হল অবৈধ কার্যকলাপ থেকে তহবিলকে তহবিল বা সম্পদে
									পরিণত করার প্রক্রিয়া যা আইনসম্মত বলে মনে হয় যাতে তহবিলের
									অবৈধ উত্স সনাক্ত করা না যায়। অর্থ পাচার প্রতিরোধ করার
									উদ্দেশ্যে।
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>4.2 : Our steps against anti-money laundering :</h3>
							<p>
								We will not do business with anyone suspected of or directly
								involved in money laundering or where funds have been sourced by
								any illegal activity. If a user is directly or indirectly
								involved in money laundering and uses our service we have the
								right to ban him/her and take necessary legal steps against
								him/her.
								<span>
									- আমরা সন্দেহভাজন বা সরাসরি অর্থ পাচারের সাথে জড়িত এমন কারও
									সাথে ব্যবসা করব না বা যেখানে কোনও অবৈধ কার্যকলাপের মাধ্যমে
									তহবিল সংগ্রহ করা হয়েছে। যদি একজন ব্যবহারকারী প্রত্যক্ষ বা
									পরোক্ষভাবে অর্থ পাচারের সাথে জড়িত থাকে এবং আমাদের পরিষেবা
									ব্যবহার করে তবে আমাদের তাকে নিষিদ্ধ করার এবং তার বিরুদ্ধে
									প্রয়োজনীয় আইনি পদক্ষেপ নেওয়ার অধিকার রয়েছে।
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>4.3 : Legal </h3>
							<p>
								We respect all the laws of the country. We will not be
								responsible in any way if our service is used for crime. We will
								provide full cooperation to the law enforcement forces of the
								country to suppress any kind of financial crime.
								<span>
									- আমরা দেশের সকল আইনের প্রতি শ্রদ্ধাশীল। আমাদের পরিষেবা
									অপরাধের জন্য ব্যবহার করা হলে আমরা কোনোভাবেই দায়ী থাকব না।
									যেকোনো ধরনের আর্থিক অপরাধ দমনে আমরা দেশের আইনশৃঙ্খলা বাহিনীকে
									সার্বিক সহযোগিতা করব।
								</span>
							</p>
						</div>
					</div>

					{/* Block  - Tools*/}
					<div className={style.block}>
						<h2>5 : Tools</h2>
						<div className={style.block__sub}>
							<h3>5.1 : Google Analytics</h3>
							<p>
								This site uses features of the google analytics web service by
								google. The tool collects device information and upon consent,
								also sets cookies. Google complies with the european data
								protection law (gdpr) and is certified under the privacy shield
								agreement.
								<span>
									- এই সাইটটি google দ্বারা google বিশ্লেষণ ওয়েব পরিষেবার
									বৈশিষ্ট্যগুলি ব্যবহার করে৷ টুলটি ডিভাইসের তথ্য সংগ্রহ করে এবং
									সম্মতির ভিত্তিতে কুকিও সেট করে। Google ইউরোপীয় ডেটা সুরক্ষা
									আইন (gdpr) মেনে চলে এবং গোপনীয়তা শিল্ড চুক্তির অধীনে
									প্রত্যয়িত।
								</span>
							</p>
						</div>
					</div>

					{/* Block - Final */}
					<div className={style.block}>
						<h2>6 : Final</h2>
						<div className={style.block__sub}>
							<h3>6.1 : Update policy</h3>
							<p>
								We may occasionally amend this privacy statement to reflect
								changes to our practices or for other administrative, statutory,
								or regulatory purposes.
								<span>
									- আমরা মাঝে মাঝে এই গোপনীয়তা বিবৃতি সংশোধন করতে পারি আমাদের
									অনুশীলনের পরিবর্তনগুলি প্রতিফলিত করতে বা অন্যান্য প্রশাসনিক,
									বিধিবদ্ধ বা নিয়ন্ত্রক উদ্দেশ্যে।
								</span>
							</p>
						</div>
						<div className={style.block__sub}>
							<h3>6.2 : Contact</h3>
							<p>
								For more information about our privacy practices, if you would
								like to make changes to your data, or if you would like to make
								a complaint, please contact us by email, whatsapp or messenger
								using the contact details that is given to this page.
								<span>
									- আমাদের গোপনীয়তা অনুশীলন সম্পর্কে আরও তথ্যের জন্য, আপনি যদি
									আপনার ডেটাতে পরিবর্তন করতে চান, বা আপনি যদি অভিযোগ করতে চান,
									তাহলে এই পৃষ্ঠায় দেওয়া যোগাযোগের বিবরণ ব্যবহার করে ইমেল,
									হোয়াটসঅ্যাপ বা মেসেঞ্জারের মাধ্যমে আমাদের সাথে যোগাযোগ করুন।
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Privacy;
