import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { registerUser } from "../../../redux/user/userAction";
import { Helmet } from "react-helmet";
//styles
import styles from "./Signup.module.scss";
// icons
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
// Components
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import WelcomeSlide from "../../../components/WelcomeSlide/WelcomeSlide";
import AuthGuard from "../../../components/AuthGuard/AuthGuard";

function Signup({ errorMessage, registerUser }) {
	const [watch, setWatch] = useState(false);
	const [watch2, setWatch2] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	// initial values
	const initVals = {
		email: "",
		number: "",
		name: "",
		password: "",
		confirmPassword: "",
	};

	// validation schema
	const signinSchema = Yup.object().shape({
		email: Yup.string().required("Email is required!"),
		number: Yup.string()
			.required("Phone is required!")
			.min(4, "Password must be 6 or more character long."),
		name: Yup.string().required("Name is required!"),
		password: Yup.string()
			.min(6, "Password must be 6 or more character long.")
			.required("Password is required!"),
		confirmPassword: Yup.string().oneOf(
			[Yup.ref("password"), null],
			"Passwords must match"
		),
	});

	// on-submit function
	const onSubmitHandeler = async (values) => {
		// 1. set loading and clear error message
		setLoading(true);
		// 2. call registerUser function to login
		let isCreated = await registerUser(
			values.email,
			values.number,
			values.name,
			values.password
		);

		// 3. If user can successfully log in just navigate user to home page else show error message
		if (isCreated) {
			setLoading(false);
			toast.success("Registered successfully");
			navigate("/login");
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<Helmet>
				<title>Registration | Dollar Buy Sell Corner</title>
				<meta name="title" content="Registration | Dollar Buy Sell Corner" />
				<meta
					name="description"
					content="Create Account Dollar Buy Sell You Can Easily exchange your dollar. We accept bkash, rocket, nogod, webmoney, perfect money, skrill, neteller, advcash, payeer, payoneer and many more."
				/>
				<meta
					name="keywords"
					content="dollar buy sell, dollar buy sell bd, buy sell dollar, usd to bd, bd dollar buy sell, dollar buy sell bangladesh, usd buy sell, dollar buy and sell, skrill dollar buy sell bd, buy sell in bd, buy sell bd,buy sell exchange, buy sell and exchange, dollar buy and sell bd, online dollar buy sell bd, trusted dollar buy sell in bangladesh"
				/>
			</Helmet>

			<Navbar />
			<AuthGuard logged="yes" roleActive={false}>
				<div className={styles.signup}>
					<div className={styles.signup__form}>
						<h1>Get's Started.</h1>
						<p>
							Join with us and and enjoy our exciting rates and exchange
							services.
						</p>

						<Formik
							initialValues={initVals}
							validationSchema={signinSchema}
							onSubmit={(values) => onSubmitHandeler(values)}
						>
							{({ errors, touched }) => (
								<Form>
									{/* ============ Email Fields ============ */}
									<div className={styles.inputGroup}>
										<label htmlFor="email">Email</label>
										<Field
											name="email"
											placeholder="Enter your email"
											type="email"
											className={
												(errorMessage && errorMessage.email) ||
												(errors.email && touched.email)
													? styles.haveerror
													: ``
											}
										/>
										<small>
											<ErrorMessage name="email" />
											{errorMessage &&
												errorMessage.email &&
												errorMessage.email[0]}
										</small>
									</div>
									{/* ============ Phone Fields ============ */}
									<div className={styles.inputGroup}>
										<label htmlFor="email">Phone no.</label>
										<Field
											name="number"
											placeholder="Enter your phone number"
											type="text"
											className={
												(errorMessage && errorMessage.number) ||
												(errors.number && touched.number)
													? styles.haveerror
													: ``
											}
										/>
										<small>
											<ErrorMessage name="number" />
											{errorMessage &&
												errorMessage.number &&
												errorMessage.number[0]}
										</small>
									</div>
									{/* ============ Name Fields ============ */}
									<div className={styles.inputGroup}>
										<label htmlFor="name">Full Name</label>
										<Field
											name="name"
											placeholder="Enter your full name"
											type="text"
											className={
												errors.name && touched.name ? styles.haveerror : ``
											}
										/>
										<small>
											<ErrorMessage name="name" />
										</small>
									</div>
									{/* ============ Password Fields ============ */}
									<div className={styles.inputGroup}>
										<label htmlFor="password">Password</label>
										<Field
											name="password"
											placeholder="Enter your password"
											type={watch ? "text" : "password"}
											className={
												errors.password && touched.password
													? `${styles.haveerror}`
													: ``
											}
										/>
										<div className={styles.eye}>
											{watch ? (
												<span onClick={() => setWatch(false)}>
													<AiOutlineEye />
												</span>
											) : (
												<span onClick={() => setWatch(true)}>
													<AiOutlineEyeInvisible />
												</span>
											)}
										</div>
										<small>
											<ErrorMessage name="password" />
										</small>
									</div>
									{/* ============ Confirm Password Fields ============ */}
									<div className={styles.inputGroup}>
										<label htmlFor="confirmPassword">Confirm Password</label>
										<Field
											name="confirmPassword"
											placeholder="Enter your password again"
											type={watch2 ? "text" : "password"}
											className={
												errors.confirmPassword && touched.confirmPassword
													? `${styles.haveerror}`
													: ``
											}
										/>
										<div className={styles.eye}>
											{watch2 ? (
												<span onClick={() => setWatch2(false)}>
													<AiOutlineEye />
												</span>
											) : (
												<span onClick={() => setWatch2(true)}>
													<AiOutlineEyeInvisible />
												</span>
											)}
										</div>
										<small>
											<ErrorMessage name="confirmPassword" />
										</small>
									</div>
									{/* ============ Submit Button ============ */}
									{loading ? (
										<button type="submit" disabled>
											Loading ...
										</button>
									) : (
										<button type="submit">Register</button>
									)}
								</Form>
							)}
						</Formik>
						<p className={styles.lastText}>
							Already have an account? <Link to="/login">Log in here.</Link>
						</p>
					</div>
					<div className={styles.signup__style}>
						<div className={styles.wrapper}>
							<WelcomeSlide />
						</div>
					</div>
				</div>
			</AuthGuard>
			<Footer />
		</>
	);
}

const mapStateToProps = (state) => ({
	errorMessage: state.user.failedMessageForRegistration,
});

export default connect(mapStateToProps, { registerUser })(Signup);
