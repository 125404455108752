import { useEffect, useState } from "react";
import style from "./Dropdown.module.scss";
import { BsChevronDown, BsCheck2 } from "react-icons/bs";
import Logo from "../../Assets/Logo";

function Dropdown({
	label,
	placeholder,
	options,
	prevValue,
	value,
	setValue,
	pageRef,
}) {
	const [openMenu, setOpenMenu] = useState(false);

	//   Handle select item functionalities
	const handleMenu = (obj) => {
		if (prevValue && prevValue.name === obj.name) {
			setValue(obj);
		} else {
			setValue(obj);
			setOpenMenu(false);
		}
	};

	useEffect(() => {
		if (pageRef && pageRef.current) {
			pageRef.current.addEventListener("click", () => {
				setOpenMenu(false);
			});
		}
	}, [pageRef]);

	return (
		<div className={style.inputGroup__input}>
			<label>{label}</label>
			<div className={style.select}>
				{/* ================== Selection title ============== */}
				<div
					className={style.select__title}
					onClick={() => setOpenMenu(!openMenu)}
				>
					{value && value.name ? (
						<>
							<div className={style.logo}>
								<img src={Logo[value.id - 1]} alt="" />
							</div>
							<div className={style.name}>{value.name}</div>
						</>
					) : (
						<div className={style.name}>{placeholder}</div>
					)}
					<div>
						<BsChevronDown />
					</div>
				</div>

				{/* ================== Selection Options ============== */}
				<div
					className={
						openMenu
							? `${style.select__options} ${style.open}`
							: `${style.select__options}`
					}
				>
					{options.map((o) => (
						<div
							key={o.id}
							className={style.option}
							onClick={() => handleMenu(o)}
						>
							<div className={style.ologo}>
								<img src={Logo[o.id - 1]} alt="" />
							</div>
							<div className={style.oname}>{o.name}</div>
							<div className={style.ocheck}>
								{o.id === value.id ? (
									<div>
										<BsCheck2 />
									</div>
								) : null}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default Dropdown;
