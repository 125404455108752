import style from "./TransactionTableForAdmin.module.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function TableDataLoading() {
	return (
		<div className={style.data} style={{ border: "none" }}>
			<div className={style.id}>
				<Skeleton width={`80%`} height={50}></Skeleton>
			</div>
			<div className={style.username}>
				<Skeleton width={`95%`} height={50}></Skeleton>
			</div>
			<div className={style.sent}>
				<Skeleton width={`80%`} height={50}></Skeleton>
			</div>
			<div className={style.received}>
				<Skeleton width={`80%`} height={50}></Skeleton>
			</div>
			<div className={style.amount}>
				<Skeleton width={`80%`} height={50}></Skeleton>
			</div>
			<div className={style.status}>
				<Skeleton width={`80%`} height={50}></Skeleton>
			</div>
		</div>
	);
}

export default TableDataLoading;
