//  PROCESS IMAGE SIZE
export const fileSizeCalculation = (fileSize) => {
	let size = fileSize;
	let finalSize = ``;

	if (size > 1024) {
		size /= 1024;
		finalSize = `${size.toFixed(10)} Kb`;
	}
	if (size > 1024) {
		size /= 1024;
		finalSize = `${size.toFixed(10)} Mb`;
	}

	return finalSize;
};

//  PROCESS FILE NAME
export const stringProcess = (str) => {
	if (str.length > 20) {
		const mstr = str.split(".");

		const name = `${mstr[0].substring(0, 8)}.....${mstr[0].substring(
			mstr[0].length - 7
		)}`;
		const type = mstr[1];

		return `${name}.${type}`;
	} else {
		return str;
	}
};
