import style from "./RateTable.module.scss";
import Logo from "../../Assets/Logo";

function RateTable({ data }) {
	return (
		<div className={style.rateTable}>
			<div className={style.head}>
				<div className={style.image}> Gateway</div>
				<div className={style.name}>Name</div>
				<div className={style.b_rate}>We buy</div>
				<div className={style.s_rate}>We sell</div>
				<div className={style.reserve}>Reserved</div>
			</div>

			{data &&
				data.map((d) => (
					<div className={style.data} key={d.id}>
						<div className={style.image}>
							<img src={Logo[d.id - 1]} alt="" />
						</div>
						<div className={style.name}>{d.name}</div>
						<div className={style.b_rate}>৳ {d.buying_rate}</div>
						<div className={style.s_rate}>৳ {d.selling_rate}</div>
						<div className={style.reserve}>
							{d.unit === "u0024" ? `$` : `৳`} {d.reserved_amount}
						</div>
					</div>
				))}
		</div>
	);
}

export default RateTable;
