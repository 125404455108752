import { useState, useEffect } from "react";
import { API } from "../../Assets/API";
import { selectLogo } from "../../misc/global";
import { FaDatabase } from "react-icons/fa";
import axios from "axios";
import style from "./TransactionTableGlobal.module.scss";
import TableDataLoading from "../TransactionTableForAdmin/TableDataLoading";
import TransationDetail from "../TransactionTableForAdmin/TransationDetail";

function TransactionTableGlobal() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const fetchData = () => {
		setLoading(true);
		axios
			.get(`${API.getUserTransaction}?page=1`)
			.then((res) => {
				let values = res.data.data.data.slice(0, 10);
				setData(values);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	// load data at the beginning & when page number is changed
	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className={style.transactionTableGlobal}>
			<h3>___ Our last 10 transactions</h3>
			{loading ? (
				<div className={style.tableWrapper}>
					{Array(8)
						.fill("")
						.map(() => (
							<TableDataLoading key={Math.random()} />
						))}
				</div>
			) : data.length > 0 ? (
				<div className={style.tableWrapper}>
					<div className={style.table}>
						<div className={style.head}>
							<div className={style.sent}>Sent______</div>
							<div className={style.received}>Received</div>
							<div className={style.amount}>Amount</div>
							<div className={style.status}>Status</div>
						</div>
						{data &&
							data.map((d) => (
								<div className={style.data} key={d.id}>
									<div className={style.sent}>
										<img src={selectLogo(d.sent)} />
										{d.sent}
									</div>
									<div className={style.received}>
										<img src={selectLogo(d.received)} />
										{d.received}
									</div>
									<div className={style.amount}>
										{d.amount} {d.sent_unit === `u0024` ? "$" : "৳"}
									</div>
									<div className={style.status}>
										<div
											className={
												d.status === "Rejected"
													? `${style.status__text} ${style.red}`
													: d.status === "Canceled"
													? `${style.status__text} ${style.red}`
													: d.status === "Hold"
													? `${style.status__text} ${style.gray}`
													: d.status === "Processing"
													? `${style.status__text} ${style.orange}`
													: d.status === "Incomplete"
													? `${style.status__text} ${style.violet}`
													: d.status === "Pending"
													? `${style.status__text} ${style.orange}`
													: `${style.status__text} ${style.green}`
											}
										>
											{d.status}
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			) : (
				<div className={style.emptyBlock}>
					<div>
						<p>
							<span>
								<FaDatabase />
							</span>
						</p>
						<h1>Not Found</h1>
						<p>There isn't any transaction.</p>
					</div>
				</div>
			)}
		</div>
	);
	<TransationDetail />;
}

export default TransactionTableGlobal;
