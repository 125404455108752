import {
  CLEAR_MESSAGE,
  FAILED,
  SET_HASHID,
  SET_RATES,
  SET_GETWAY
} from "./transactionType";
import { GET_TRANSACTIONS } from "./transactionType";

// INITIAL STATE
const initialState = {
  rateLoading: false,
  errorMessage: "",
  transactionHash: "",
  transactions: [],
  gateway: [],
  rates: [],
};

// USER REDUCER
const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HASHID:
      return{
        ...state,
        transactionHash: action.hash,
      }
    case SET_GETWAY:
      return {
        ...state,
        gateway: [...action.data],
      };
    case SET_RATES:
      return {
        ...state,
        rates: [...action.data],
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.data,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        errorMessage: "",
      };
    case FAILED:
      return {
        ...state,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default transactionReducer;
