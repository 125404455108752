import Skeleton from "react-loading-skeleton";
import style from "./ExchangeFormLoader.module.scss";

function ExchangeFomLoader() {
	return (
		<div className={style.exchangeFormLoader}>
			<h3>
				<Skeleton width={`50%`} height={30}></Skeleton>
			</h3>

			<form>
				<div className={style.inputGroup}>
					<div className={style.drop}>
						<Skeleton width={`100%`} height={60}></Skeleton>
					</div>
					<div className={style.drop}>
						<Skeleton width={`100%`} height={60}></Skeleton>
					</div>
				</div>

				<div className={style.calculation}>
					<Skeleton width={`100%`} height={60}></Skeleton>
				</div>
				<div className={style.calculation2}>
					<Skeleton width={`100%`} height={200}></Skeleton>
				</div>

				<div className={style.button}>
					<Skeleton width={`25%`} height={50}></Skeleton>
				</div>
			</form>
		</div>
	);
}

export default ExchangeFomLoader;
