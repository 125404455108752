import * as React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { questions } from "../../../Assets/Questions";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

// Styles
import style from "./Help.module.scss";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { BsChevronDown } from "react-icons/bs";

// Accordion
const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	"&:not(:last-child)": { borderBottom: 0 },
	"&:before": {
		display: "none",
	},
}));

// Accordion Summary
const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
	({ theme }) => ({
		backgroundColor:
			theme.palette.mode === "dark"
				? "rgba(255, 255, 255, .05)"
				: "rgba(0, 0, 0, .03)",
		flexDirection: "row-reverse",
		"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
			transform: "rotate(90deg)",
		},
		"& .MuiAccordionSummary-content": { marginLeft: theme.spacing(1) },
	})
);

// Accordion details
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// /////////////////////////////////////////////////////////
// Main function
function Help() {
	const [expanded, setExpanded] = React.useState("panel1");

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Helmet>
				<title>Dollar Buy Sell Corner</title>
				{/* <meta name="title" content="Help | Dollar Buy Sell Corner" />
				<meta
					name="description"
					content="Trusted Dollar Buy Sell Site Dollar Buy Sell Corner. You can buy sell and exchange all kinds of dollars online from our site from WebMoney to Perfect Money to PayPal to Payeer to Skrill."
				/>
				<meta
					name="keywords"
					content="dollar buy sell, dollar exchange, dollar exchange bd, dollar buy sell bd, dollar buy sell bangladesh, dollar sell bd, skrill dollar buy sell bd, usd buy sell, dollar buy sell website, dollar buy sell in bangladesh"
				/> */}
			</Helmet>

			<Navbar />
			<div className={style.help}>
				<div className={style.help__container}>
					<h1>Help</h1>

					<div className={style.questions}>
						{questions.map((q) => (
							<Accordion
								className={style.accor}
								expanded={expanded === q.id}
								onChange={handleChange(q.id)}
							>
								<AccordionSummary
									className={style.summary}
									aria-controls={`panel${q.id}d-content`}
									id={`panel${q.id}d-header`}
								>
									<div className={style.summary__block}>
										<div className={style.text}>{q.question}</div>
										<div className={style.logo}>
											<BsChevronDown />
										</div>
									</div>
								</AccordionSummary>
								<AccordionDetails className={style.details}>
									<p>{q.answer}</p>
									<p className={style.answerB}>{q.answerB}</p>
								</AccordionDetails>
							</Accordion>
						))}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Help;
