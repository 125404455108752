import { useEffect, useState, useRef } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
// Components
import Navbar from "../../../components/Navbar/Navbar";
import TransactionTableForAdmin from "../../../components/TransactionTableForAdmin/TransactionTableForAdmin";
import TableDataLoading from "../../../components/TransactionTableForAdmin/TableDataLoading";
// Assets
import style from "./Daashboard.module.scss";
import { API } from "../../../Assets/API";
import dataImage from "../../../Assets/Exchange/data.png";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import AuthGuard from "../../../components/AuthGuard/AuthGuard";

function Daashboard() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(0);
	const pageRef = useRef(null);

	// handle pagination
	const handlePageClick = (event) => {
		setCurrentPage(event.selected + 1);
	};

	// fetch data
	const fetchData = (pageNumber) => {
		setLoading(true);
		axios
			.get(`${API.getAllTransactions}?page=${pageNumber}`)
			.then((res) => {
				setLastPage(res.data.data.last_page);
				setData(res.data.data.data);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	// load data at the beginning & when page number is changed
	useEffect(() => {
		fetchData(currentPage);
	}, [currentPage]);

	return (
		<>
			<Navbar />
			<AuthGuard logged="no" roleActive={true}>
				<div className={style.dashboard} ref={pageRef}>
					{/* primary heading */}
					<h1>
						Transaction <span>List</span>
					</h1>
					<p>Check the latest update of your information.</p>

					{/* table wrapper */}
					<div className={style.tableWrapper}>
						{loading ? (
							<div className={style.table}>
								{Array(12)
									.fill("")
									.map(() => (
										<TableDataLoading key={Math.random()} />
									))}
							</div>
						) : data.length > 0 ? (
							<div className={style.table}>
								<TransactionTableForAdmin pageRef={pageRef} data={data} />
							</div>
						) : (
							<div className={style.emptyBlock}>
								<img src={dataImage} alt="Data error" />
								<div className={style.text}>
									<h2>Empty Data Table</h2>
									<p>We are sorry but we can't find any transaction data.</p>
								</div>
							</div>
						)}
					</div>

					{/* pagination */}
					{data.length > 0 ? (
						<ReactPaginate
							breakLabel={window.innerWidth >= 700 ? "..." : ""}
							nextLabel={<BsChevronRight />}
							previousLabel={<BsChevronLeft />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={window.innerWidth >= 700 ? 5 : 2}
							pageCount={lastPage}
							renderOnZeroPageCount={null}
							containerClassName={style.paginationBttns}
							previousLinkClassName={style.previousBtn}
							nextLinkClassName={style.nextsBtn}
							disabledClassName={style.paginationDisabled}
							activeClassName={style.paginationActive}
						/>
					) : null}
				</div>
				;
			</AuthGuard>
		</>
	);
}

export default Daashboard;
