import { useState } from "react";
import style from "./TransactionTable.module.scss";
import { FiRefreshCcw, FiPaperclip } from "react-icons/fi";
import { BsShieldExclamation, BsShieldCheck, BsShieldX, BsPauseCircle, BsTrash} from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { FaCircle } from "react-icons/fa";
import { processDate } from "../../utils/processDate";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../../Assets/API";
import toast from "react-hot-toast";

function TableData({ date, transaction, filterTransaction }) {
  const [cancelOption, setCancelOption] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [loading, setLoading] = useState(false);

  const handlePopup = (id, status) => {
    setCancelOption(true);
    setSelectedId(id);
    setSelectedStatus(status);
  }

  const cancelFunc = () => {
    if(selectedStatus === "Incomplete" || selectedStatus === "Pending"){
      setLoading(true);
      axios.patch(`${API.newTransaction}/${selectedId}/cancel`)
      .then(() => {
        setLoading(false);
        toast.success("Transaction cancelled successfully.");
        filterTransaction(selectedId);
        setCancelOption(false);
      })
      .catch(() => {
        setLoading(false);
        setCancelOption(false);
        toast.error("Something went wrong !");
      })
    }
    else{
      toast.error("You can't cancel this transaction.");
    }
  }

  return (
    <>
      <h3>{processDate(date).date}</h3>
      <div className={style.tdata}>
        {transaction.map((t) => (
          <div className={style.tdata__data} key={t.id}>
            {/* ////////////////////////////////////////////////////// */}
            {/* logo */}
            <div
              className={
                t.status === "Rejected"
                  ? `${style.logo} ${style.red}`
                  : t.status === "Canceled"
                  ? `${style.logo} ${style.red}`
                  : t.status === "Hold"
                  ? `${style.logo} ${style.gray}`
                  : t.status === "Processing"
                  ? `${style.logo} ${style.orange}`
                  : t.status === "Incomplete"
                  ? `${style.logo} ${style.violet}`
                  : t.status === "Pending"
                  ? `${style.logo} ${style.orange}`
                  : ` ${style.logo} ${style.green}`
              }
            >
              <div className={style.logo__inner}>
                {t.status === "Rejected" || t.status === "Canceled" ? (
                  <BsShieldX />
                ) : t.status === "Processing" || t.status === "Pending" ? (
                  // <FiCornerUpRight />
                  <FiRefreshCcw />
                ) : t.status === "Hold" ? (
                  <BsPauseCircle />
                ) : t.status === "Incomplete" ? (
                  <BsShieldExclamation />
                ) : (
                  <BsShieldCheck />
                )}
              </div>
            </div>

            {/* ////////////////////////////////////////////////////// */}
            {/* name */}
            {
              t.status === "Incomplete"
              ? <Link to={`/transaction/${t.sent}/${t.received}/${t.hash_id}`} className={style.info}>
                  <div className={style.info__main}>
                    {t.type} {t.sent}
                    {" to "}
                    {t.received}
                  </div>
                  <div className={style.info__other}>
                    {processDate(t.date_time).date} | {processDate(t.date_time).time}
                  </div>
                </Link>
              : <div className={style.info}>
                  <div className={style.info__main}>
                    {t.type} {t.sent}
                    {" to "}
                    {t.received}
                  </div>
                  <div className={style.info__other}>
                    {processDate(t.date_time).date} | {processDate(t.date_time).time}
                  </div>
                </div>
            }
            
            {/* ////////////////////////////////////////////////////// */}
            {/* status */}
            <div className={style.status}>
              <div className={style.status__color}>
                <div
                  className={
                    t.status === "Rejected"
                      ? `${style.logo} ${style.red}`
                      : t.status === "Canceled"
                      ? `${style.logo} ${style.red}`
                      : t.status === "Hold"
                      ? `${style.logo} ${style.gray}`
                      : t.status === "Processing"
                      ? `${style.logo} ${style.orange}`
                      : t.status === "Incomplete"
                      ? `${style.logo} ${style.violet}`
                      : t.status === "Pending"
                      ? `${style.logo} ${style.orange}`
                      : ` ${style.logo} ${style.green}`
                  }
                ></div>
              </div>
              <div className={style.status__text}>{t.status}</div>
            </div>

            {/* ////////////////////////////////////////////////////// */}
            {/* id */}
            <div className={style.id}>
              {
                t.status === "Incomplete"
                ? <Link to={`/transaction/${t.sent}/${t.received}/${t.hash_id}`}>
                    <span><FiPaperclip /></span>{t.id}
                  </Link>
                : <div>
                    <span><FiPaperclip /></span>{t.id}
                  </div>
              }
            </div>

            {/* ////////////////////////////////////////////////////// */}
            {/* sent amount */}
            <div className={style.price}>
              {t.amount}
              <span>{t.sent_unit === `u0024` ? "$" : "৳"}</span>
            </div>
            
            {/* ////////////////////////////////////////////////////// */}
            {/* received amount */}
            <div className={style.price}>
              {t.received_amount}
              <span>{t.received_unit === `u0024` ? "$" : "৳"}</span>
            </div>

            {/* ////////////////////////////////////////////////////// */}
            {/* options */}
            <div className={style.option}>
              {
                t.status === "Incomplete" || t.status === "Pending"
                ? <>
                    <Link className={style.edit} to={`/transaction/${t.hash_id}`}>
                      <FiEdit2/>
                      <FaCircle/>
                    </Link>
                    <div className={style.cancel} onClick={() => handlePopup(t.id, t.status)}><BsTrash/></div>
                  </>
                : null
              }
            </div>
          </div>
        ))}
      </div>

      {
        cancelOption
        ? <div className={style.popup} onClick={() => setCancelOption(false)}>
            <div className={style.popupWrapper} onClick={(e) => {e.stopPropagation()}}>
              <div className={style.icon}>
                <BsTrash />
              </div>
              <h3>Cancel Transaction ?</h3>
              <p>This action can not be undone</p>
              <div className={style.btnGroup}>
                <button onClick={() => setCancelOption(false)}>Go Back</button>
                {
                  loading
                  ? <button>Cancelling...</button>
                  : <button onClick={cancelFunc}>Cancel</button>
                }
              </div>
            </div>
          </div>
        : null
      }
    </>
  );
}

export default TableData;
