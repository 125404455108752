import style from "./PopUp.module.scss";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";

function PopUp({ image, heading, text, offPopUp, btnText=``, link=`` }) {
  return (
    <div className={style.popupWrapper} onClick={() => offPopUp()}>
      <div className={style.popup} onClick={(event) => event.stopPropagation()}>
        <img src={image} alt="" />
        <h4>{heading}</h4>
        <p>{text}</p>
        <button onClick={() => offPopUp()}><FiX/></button>
        
        {
          btnText !== ``
          ? <Link to={link}>{btnText}</Link>
          : null
        }
      </div>
    </div>
  );
}

export default PopUp;
