import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
// Styles & Images
import style from "./Exchange2.module.scss";
import cmpltImage from "../../../Assets/Exchange/completed.png";
import loginImage from "../../../Assets/Exchange/login.png";
import dataImage from "../../../Assets/Exchange/data.png";
import coins from "../../../Assets/Exchange/coins.png";
// Components
import {
	ExchangeWrapper,
	FormWrapper,
	MessageWrapper,
} from "../../../components/ExchangeWrapper/ExchangeWrapper";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import ExchangeDesclaimer from "../../../components/ExchangeDesclaimer/ExchangeDesclaimer";
import ExchangePrevInfo from "../../../components/ExchangePrevInfo/ExchangePrevInfo";
import PopUp from "../../../components/PopUp/PopUp";
// API
import { API } from "../../../Assets/API";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
	getGateWay,
	getRates,
} from "../../../redux/transaction/transactionAction";
import ExchangeConfirmForm from "../../../components/ExchangeConfirmForm/ExchangeConfirmForm";
import Skeleton from "react-loading-skeleton";

function Exchange2() {
	const [dataLoading, setDataloading] = useState(false);
	const [popup, setPopup] = useState("");
	const [detail, setDetail] = useState({});
	const user = useSelector((state) => state.user);
	const rates = useSelector((state) => state.transaction.rates);
	const gateway = useSelector((state) => state.transaction.gateway);
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		const hash = location.pathname.split("/")[2];
		if (user && user.currentUser) {
			setDataloading(true);
			// get transaction information
			axios
				.get(`${API.getUserTransaction}/${hash}`)
				.then((res) => {
					setDetail(res.data.data);
					// get gateway & rates
					if (rates.length === 0 || gateway.length === 0) {
						axios
							.get(API.getRates)
							.then((rates) => {
								axios
									.get(API.getGateway)
									.then((gateway) => {
										dispatch(getRates(rates.data.data));
										dispatch(getGateWay(gateway.data.data));
										setDataloading(false);
									})
									.catch(() => {
										setDataloading(false);
										setPopup("network error");
									});
							})
							.catch(() => {
								setPopup("network error");
							});
					} else {
						setDataloading(false);
					}
				})
				.catch(() => {
					setDataloading(false);
				});
		}
	}, [user.currentUser, location.pathname, rates.length, gateway.length]);

	return (
		<>
			<Helmet>
				<title>
					Dollar buy sell | Trusted dollar buy sell bd | Dollar Buy Sell Corner
				</title>
				<meta
					name="title"
					content="Dollar buy sell | Trusted dollar buy sell bd | Dollar Buy Sell Corner"
				/>
				<meta
					name="description"
					content="Dollar Buy Sell Corner is a online trusted dollar buy sell service that provides a fast & secure transaction of skrill, pyypl, perfect money, web money, USDT"
				/>
				<meta
					name="keywords"
					content="dollar buy sell, dollar buy sell bd, usd to bd, bd dollar buy sell,  pyypl dollar buy sell bd, trusted dollar buy sell site in bd, usd buy sell,  skrill dollar buy sell bd, dollar buy and sell bd,  trusted dollar buy sell in bangladesh"
				/>
			</Helmet>

			<Navbar />
			<div className={style.exchange}>
				<ExchangeWrapper>
					{/* ////////////////////////////////// Form ////////////////////////////////// */}
					<FormWrapper
						heading={`Confirm Transaction`}
						headingText={`Please make payment to our account and insert your payment details in the feild below.`}
					>
						<div className={style.exchange__form}>
							<div className={style.accountNumber}>
								<div className={style.image}>
									<img src={coins} alt="" />
								</div>
								<div className={style.text}>
									<p>
										Send your sent-amount to this{" "}
										{detail.sent_id !== 4 && detail.sent_id !== 5
											? `personal`
											: ``}{" "}
										<span>{detail.sent}</span> account
									</p>
									<h1>
										{detail && gateway && gateway[detail.sent_id - 1] ? (
											gateway[detail.sent_id - 1].account_number
										) : (
											<Skeleton width={`50%`} height={`25px`} />
										)}
									</h1>
								</div>
							</div>
							<ExchangeConfirmForm detail={detail} setPopup={setPopup} />
						</div>
					</FormWrapper>
					{/* ////////////////////////////////// Message ////////////////////////////////// */}
					<MessageWrapper>
						<ExchangePrevInfo detail={detail} loading={dataLoading} />
						<ExchangeDesclaimer heading={`Desclaimer`} headingText={``} />
					</MessageWrapper>
				</ExchangeWrapper>
			</div>
			<Footer />

			{/* ///////////////////////////////Popups//////////////////////////// */}
			{popup === "transaction completed" ? (
				<PopUp
					image={cmpltImage}
					heading={`Transaction completed!`}
					text={`Thank you for being with us. To track your transaction please go to your transaction page.`}
					offPopUp={setPopup}
				/>
			) : popup === "auth error" ? (
				<PopUp
					image={loginImage}
					heading={`Authentication Error!`}
					text={`Please login again to complete your transaction.`}
					offPopUp={setPopup}
				/>
			) : popup === "network error" ? (
				<PopUp
					image={dataImage}
					heading={`Network Error`}
					text={`We can't fetch important data due to network issue.`}
					offPopUp={setPopup}
				/>
			) : null}
		</>
	);
}

export default Exchange2;
