import Logo from "../Assets/Logo";

export const selectLogo = (text) => {
	if (text === "Bk personal") return Logo[0];
	else if (text === "Rocket personal") return Logo[1];
	else if (text === "Nagad personal") return Logo[2];
	else if (text === "Perfect Money") return Logo[3];
	else if (text === "Skrill") return Logo[4];
	else if (text === "Tether USDT ( trc20 )") return Logo[5];
	else if (text === "TRON TRX") return Logo[6];
	else if (text === "Web Money") return Logo[7];
	else if (text === "PAYEER") return Logo[8];
	else if (text === "AstroPay") return Logo[9];
	else if (text === "MoneyGo") return Logo[10];
	else if (text === "STICPAY") return Logo[11];
	else if (text === "AdvCash") return Logo[12];
	else if (text === "Airtm") return Logo[13];
	else if (text === "Pyypl") return Logo[14];
	else if (text === "Skrill non-Gambling") return Logo[15];
	else if (text === "Payoneer") return Logo[16];
	else if (text === "Upay") return Logo[17];
	else if (text === "Bk agent") return Logo[18];
	else if (text === "Nagad agent") return Logo[19];
	else if (text === "Rocket agent") return Logo[20];
	else if (text === "BEP20 ( BUSD, BNB, USDT )") return Logo[19];
	else if (text === "Coinbase BTC") return Logo[23];
	else if (text === "Coinbase LTC") return Logo[23];
	else if (text === "Coinbase ETH") return Logo[23];
	else if (text === "Neteller") return Logo[25];
	else if (text === "Neteller non-Gambling") return Logo[26];
};
