import style from "./ExchangePrevInfo.module.scss";
import Skeleton from "react-loading-skeleton";
// Assets
import { FiDollarSign, FiCalendar, FiActivity } from "react-icons/fi";
import { processDate } from "../../utils/processDate";
// images
import bkash from "../../Assets/Exchange/bkash.png";
import nogod from "../../Assets/Exchange/nogod.png";
import rocket from "../../Assets/Exchange/rocket.png";
import perfectMoney from "../../Assets/Exchange/perfectMoney.png";
import skrill from "../../Assets/Exchange/skrill.png";
import tron from "../../Assets/Exchange/tron-trx.png";
import astro from "../../Assets/Exchange/astro-pay.png";
import wm from "../../Assets/Exchange/web-money.png";
import payeer from "../../Assets/Exchange/payeer.jpg";
import mg from "../../Assets/Exchange/money-go.png";
import tether from "../../Assets/Exchange/tether.png";
import sticpay from "../../Assets/Exchange/sticpay.png";
import airtm from "../../Assets/Exchange/airtm.png";
import adv from "../../Assets/Exchange/adv.png";
import pyypl from "../../Assets/Exchange/pyypl.png";

const imageArray = [
	bkash,
	rocket,
	nogod,
	perfectMoney,
	skrill,
	tether,
	tron,
	wm,
	payeer,
	astro,
	mg,
	sticpay,
	adv,
	airtm,
	pyypl,
];

function ExchangePrevInfo({ loading, detail }) {
	return (
		<div className={style.epi}>
			<h2>Exchange Info</h2>

			<div className={style.epi__info}>
				{/* sent & received */}
				<div className={style.infoContainer}>
					<div className={style.info}>
						<div className={style.info__logo}>
							<img src={imageArray[detail.sent_id - 1]} alt="" />
						</div>
						<div className={style.info__text}>
							<p>SENT</p>
							{loading ? (
								<h5>
									<Skeleton width={"70%"} height="20px" />
								</h5>
							) : (
								<h5>{detail.sent}</h5>
							)}
						</div>
					</div>
					<div className={style.info}>
						<div className={style.info__logo}>
							<img src={imageArray[detail.received_id - 1]} alt="" />
						</div>
						<div className={style.info__text}>
							<p>RECEIVED</p>
							{loading ? (
								<h5>
									<Skeleton width={"70%"} height="20px" />
								</h5>
							) : (
								<h5>{detail.received}</h5>
							)}
						</div>
					</div>
				</div>

				{/* sent amount & received mount */}
				<div className={style.infoContainer}>
					<div className={style.info}>
						<div className={style.info__logo}>
							{detail.sent_id === 4 || detail.sent_id === 5 ? (
								<FiDollarSign />
							) : (
								<>৳</>
							)}
						</div>
						<div className={style.info__text}>
							<p>SENT AMOUNT</p>
							{loading ? (
								<h5>
									<Skeleton width={"50%"} height="20px" />
								</h5>
							) : (
								<h5>{detail.amount}</h5>
							)}
						</div>
					</div>
					<div className={style.info}>
						<div className={style.info__logo}>
							{detail.received_id === 4 || detail.received_id === 5 ? (
								<FiDollarSign />
							) : (
								<>৳</>
							)}
						</div>
						<div className={style.info__text}>
							<p>RECEIVED AMOUNT</p>
							{loading ? (
								<h5>
									<Skeleton width={"50%"} height="20px" />
								</h5>
							) : (
								<h5>{detail.received_amount}</h5>
							)}
						</div>
					</div>
				</div>

				{/* sent amount & received mount */}
				<div className={style.infoContainer}>
					<div className={style.info}>
						<div className={style.info__logo}>
							<FiCalendar />
						</div>
						<div className={style.info__text}>
							<p>DATE</p>
							{loading ? (
								<h5>
									<Skeleton width={"80%"} height="20px" />
								</h5>
							) : detail && detail.date_time ? (
								processDate(detail.date_time).date
							) : (
								""
							)}
						</div>
					</div>
					<div className={style.info}>
						<div className={style.info__logo}>
							<FiActivity />
						</div>
						<div className={style.info__text}>
							<p>STATUS</p>
							{loading ? (
								<h5>
									<Skeleton width={"60%"} height="20px" />
								</h5>
							) : (
								<h5>{detail.status}</h5>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExchangePrevInfo;
