import { Helmet } from "react-helmet";
import Clock from "react-live-clock";
// Assets
import style from "./Transaction.module.scss";
import { BsCalendar } from "react-icons/bs";
// Components
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import TransactionTable from "../../../components/TransactionTable/TransactionTable";
import AuthGuard from "../../../components/AuthGuard/AuthGuard";

function Transaction() {
	return (
		<>
			<Helmet>
				<title>Dollar Buy Sell Corner</title>
				<meta name="title" content="Transaction | Dollar Buy Sell Corner" />
				<meta
					name="description"
					content="Dollar Buy Sell Corner is a simple and fast e-currency exchange service."
				/>
			</Helmet>

			<Navbar />
			<AuthGuard logged="no" roleActive={false}>
				<div className={style.profile}>
					{/* ======================== TRANSACTIONS ====================== */}
					<div className={style.table}>
						{/* primary heading */}
						<h1>
							Welcome <span>Back</span>
						</h1>
						<p>Check the latest update of your information.</p>

						{/* secondary heading */}
						<div className={style.head}>
							<div className={style.head__left}>
								<h3>Transactions</h3>
							</div>
							<div className={style.head__option}>
								<div>
									<span>
										<BsCalendar />
									</span>
									<Clock
										date={new Date()}
										format={"DD MMM YYYY | h:mm:ss A"}
										ticking={true}
									/>
								</div>
								<div></div>
							</div>
						</div>

						{/* table */}
						<TransactionTable />
					</div>
				</div>
			</AuthGuard>
			<Footer />
		</>
	);
}

export default Transaction;
