import { useState } from "react";
// Assets
import style from "./UserDetail.module.scss";
import { FiMail, FiActivity, FiPhone } from "react-icons/fi";
import { AiOutlineIdcard, AiOutlineClose } from "react-icons/ai";
import { BsFileEarmarkImageFill, BsFillCircleFill } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { APP_URL } from "../../Assets/API";
// Components
import StatusChangeButton from "./StatusChangeButton";
import { processDate } from "../../utils/processDate";

function UserDetail({ data, setOpen }) {
  const [selectedStatus, setSelectedStatus] = useState(data.status);

  return (
    <div className={style.userDetail}>
      <div className={style.wrapper} onClick={() => setOpen(false)}>
        <div className={style.info} onClick={(e) => e.stopPropagation()}>
          <div className={style.info__content}>
            {/* =================== close button visible for mobile only =================== */}
            <div className={style.closeButton} onClick={() => {setOpen(false)}}>
              <AiOutlineClose/>
            </div>

            {/* =================== Heading =================== */}
            <h2>{data.user_name}</h2>
            <p
              className={
                data.user_role === 1
                  ? style.superAdmin
                  : data.user_role === 1
                  ? style.admin
                  : style.customer
              }
            >
              {data.user_role === 1
                ? "Super Admin"
                : data.user_role === 1
                ? "Admin"
                : "Customer"}
            </p>

            {/* =================== Personal Information =================== */}
            <div className={style.blocks}>
              {/* email */}
              <div className={style.block}>
                <div className={style.block__logo}>
                  <FiMail />
                </div>
                <div className={style.block__name}>{data.user_email}</div>
              </div>
              {/* phone */}
              <div className={style.block}>
                <div className={style.block__logo}>
                  <FiPhone />
                </div>
                <div className={style.block__name}>{data.user_number}</div>
              </div>
              {/* status */}
              <div className={style.block}>
                <div className={style.block__logo}>
                  <FiActivity />
                </div>
                <div className={style.block__name}>
                  <span
                    className={
                      selectedStatus === "pending"
                        ? `${style.orange}`
                        : selectedStatus === "incomplete"
                        ? `${style.violet}`
                        : selectedStatus === "rejected"
                        ? `${style.red}`
                        : `${style.green}`
                    }
                  >
                    <BsFillCircleFill />
                  </span>
                  {selectedStatus}
                </div>
              </div>
              {/* join at */}
              <div className={style.block}>
                <div className={style.block__logo}>
                  <BiTime />
                </div>
                <div className={style.block__name}>
                  {processDate(data.registration_date).date} | {processDate(data.registration_date).time}
                </div>
              </div>
            </div>

            {/* =================== NID information =================== */}
            <div className={style.blocks}>
              <h4>NID information</h4>
              <div className={style.block}>
                <div className={style.block__logo}>
                  <AiOutlineIdcard />
                </div>
                <div className={style.block__name}>
                  {data.nid_number && data.nid_number.length > 0
                    ? data.nid_number
                    : "NID number is not given yet."}
                </div>
              </div>
              <div className={`${style.block} ${style.nidImage}`}>
                <div className={style.front}>
                  {data.nid_front ? (
                    <img src={`${APP_URL}/nid/${data.nid_front}`} alt="" />
                  ) : (
                    <div>
                      <BsFileEarmarkImageFill />
                      <h6>Image is not given yet.</h6>
                    </div>
                  )}
                </div>
                <div className={style.back}>
                  {data.nid_front ? (
                    <img src={`${APP_URL}/nid/${data.nid_back}`} alt="" />
                  ) : (
                    <div>
                      <BsFileEarmarkImageFill />
                      <h6>Image is not given yet.</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* =================== Status change button =================== */}
            <div className={style.scButton}>
              <StatusChangeButton changeStatus={setSelectedStatus} userId={data.user_id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetail;
