import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Styles
import Style from "./Navbar.module.scss";
// Icons
import { BiHome, BiBarChart, BiData } from "react-icons/bi";
import { BsCircleFill } from "react-icons/bs";
import { FiLogIn, FiUser, FiLogOut, FiUsers } from "react-icons/fi";
import { GrContact } from "react-icons/gr";
import { RiExchangeDollarLine } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import { AiOutlineUserAdd } from "react-icons/ai";
import logoImage from "../../Assets/Logo/Exchange-corner.png";
// Actions
import { logoutUser } from "../../redux/user/userAction";

const SideDrawer = ({ show }) => {
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	// SELECT DRAWER CLASS
	let drawerClass = `${Style.side__drawer}`;
	if (show) {
		drawerClass = `${Style.side__drawer} ${Style.open}`;
	}

	// LOGOUT FUNCTION
	const doLogout = () => {
		dispatch(logoutUser);
		// if user is in profile page then navigate to homepage
		if (location.pathname === "/profile") {
			navigate("/");
		}
	};

	return (
		<>
			<nav className={drawerClass}>
				<div className={Style.Drawerheading}>
					<Link to="/">
						<img src={logoImage} alt="" />
					</Link>
				</div>
				<div className={Style.drawerLink}>
					<div className={Style.up}>
						<NavLink
							className={(navData) =>
								navData.isActive ? Style.activeLink : ``
							}
							to="/"
						>
							<span>
								<RiExchangeDollarLine />
							</span>
							Exchange
						</NavLink>
						<NavLink
							className={(navData) =>
								navData.isActive ? Style.activeLink : ``
							}
							to="/rates"
						>
							<span>
								<BiBarChart />
							</span>
							Rates
						</NavLink>
						{user.currentUser &&
						(user.currentUser.user_role === 1 ||
							user.currentUser.user_role === 2) ? (
							<>
								<NavLink
									className={(navData) =>
										navData.isActive ? Style.activeLink : ``
									}
									to="/admin/transactions"
								>
									<span>
										<GrTransaction />
									</span>
									User Transactions
								</NavLink>
								<NavLink
									className={(navData) =>
										navData.isActive ? Style.activeLink : ``
									}
									to="/admin/users"
								>
									<span>
										<FiUsers />
									</span>
									User List
								</NavLink>
							</>
						) : (
							<>
								<NavLink
									className={(navData) =>
										navData.isActive ? Style.activeLink : ``
									}
									to="/about"
								>
									<span>
										<BiHome />
									</span>
									About Us
								</NavLink>
								<NavLink
									className={(navData) =>
										navData.isActive ? Style.activeLink : ``
									}
									to="/contacts"
								>
									<span>
										<GrContact />
									</span>
									Contact
								</NavLink>
							</>
						)}

						{user.isLogedIn ? (
							<>
								<NavLink
									className={(navData) =>
										navData.isActive ? Style.activeLink : ``
									}
									to="/profile"
								>
									<span>
										<FiUser />
									</span>
									Profile
									{user.currentUser &&
									user.currentUser.status === "incomplete" ? (
										<span>
											<BsCircleFill />
										</span>
									) : null}
								</NavLink>
								<NavLink
									className={(navData) =>
										navData.isActive ? Style.activeLink : ``
									}
									to="/transactions"
								>
									<span>
										<BiData />
									</span>
									Transactions
								</NavLink>
								<div
									className={(navData) =>
										navData.isActive ? Style.activeLink : ``
									}
									onClick={doLogout}
								>
									<span>
										<FiLogOut />
									</span>
									Logout
								</div>
							</>
						) : (
							<>
								<NavLink
									to="/login"
									className={(navData) =>
										navData.isActive ? Style.activeLink : ``
									}
								>
									<span>
										<FiLogIn />
									</span>
									Login
								</NavLink>
								<NavLink
									to="/register"
									className={(navData) =>
										navData.isActive ? Style.activeLink : ``
									}
								>
									<span>
										<AiOutlineUserAdd />
									</span>
									Register
								</NavLink>
							</>
						)}
					</div>
				</div>
			</nav>
		</>
	);
};

export default SideDrawer;
