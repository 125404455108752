import { useState } from "react";
import axios from "axios";
import { BiErrorCircle } from "react-icons/bi";
import { API } from "../../Assets/API";
import style from "./NidUploadForm.module.scss";
import fileImage from "../../Assets/Profile/files.png";
// Components
import NidField from "./NidField";

function NidUploadForm({ setChange, setPopup }) {
	const [front, setFront] = useState();
	const [back, setBack] = useState();
	const [nidNumb, setNidNumb] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState();

	const validation = () => {
		if (nidNumb && front && back) {
			if (nidNumb.length < 2) {
				setError("Minimum length of NID number is 2");
				return false;
			}
			setError("");
			return true;
		} else {
			setError("All fields are required.");
			return false;
		}
	};

	const handleSubmit = () => {
		if (validation()) {
			setLoading(true);

			const data = new FormData();
			data.append("nid_back", back);
			data.append("nid_front", front);
			data.append("nid_number", nidNumb);

			axios
				.post(API.verifyNid, data, {
					onUploadProgress: (data) => {
						setProgress(Math.round((100 * data.loaded) / data.total));
					},
				})
				.then((res) => {
					setPopup(true);
					setChange(false);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					if (err.response.data.errors) {
						if (
							err.response.data.errors.nid_number &&
							err.response.data.errors.nid_number[0]
						) {
							setError(err.response.data.errors.nid_number[0]);
						} else if (
							err.response.data.errors.nid_front &&
							err.response.data.errors.nid_front[0]
						) {
							setError(err.response.data.errors.nid_front[0]);
						} else if (
							err.response.data.errors.nid_back &&
							err.response.data.errors.nid_back[0]
						) {
							setError(err.response.data.errors.nid_back[0]);
						}
					}
				});
		}
	};

	return (
		<div className={style.nidForm}>
			<div className={style.nidNumbField}>
				<label>NID - Number</label>
				<input
					type="text"
					value={nidNumb}
					onChange={(e) => setNidNumb(e.target.value)}
				/>
			</div>

			{/* NID fields or  */}
			{loading ? (
				<>
					<div className={style.uploading}>
						<img src={fileImage} alt="" />
						<h3>Uploading Files</h3>
						<h1>{progress}%</h1>
					</div>
				</>
			) : (
				<>
					<NidField
						label="NID - Front side"
						setFile={setFront}
						setError={setError}
					/>
					<div style={{ marginTop: "3rem" }}></div>
					<NidField
						label="NID - Back side"
						setFile={setBack}
						setError={setError}
					/>
				</>
			)}

			<small>
				{error.length > 0 ? (
					<span>
						<BiErrorCircle /> {error}
					</span>
				) : (
					""
				)}
			</small>

			<div className={style.buttonBox}>
				<button className={style.cancel} onClick={() => setChange(false)}>
					Cancel
				</button>
				{!loading ? (
					<button className={style.submitButton} onClick={handleSubmit}>
						Upload Image
					</button>
				) : (
					<button className={style.submitButton} disabled>
						Loading...
					</button>
				)}
			</div>
		</div>
	);
}

export default NidUploadForm;
