export const APP_URL = "https://api.dollarbuysellcorner.com/api/v1";

export const API = {
	// user
	login: `${APP_URL}/login`,
	register: `${APP_URL}/register`,
	getUserData: `${APP_URL}/user`,
	verifyNid: `${APP_URL}/verify`,
	changePassword: `${APP_URL}/password-change`,
	updateProfile: `${APP_URL}/update-profile`,
	userList: `${APP_URL}/users`,
	userDetail: `${APP_URL}/user`,
	// rates & gateway)
	getRates: `${APP_URL}/rates`,
	getGateway: `${APP_URL}/gateway-details`,
	// Transaction
	newTransaction: `${APP_URL}/transaction`,
	confirmTransaction: `${APP_URL}/transaction-details`,
	updateTransactionStatus: `${APP_URL}/transaction/update-status`,
	getUserTransaction: `${APP_URL}/transactions`,
	getAllTransactions: `${APP_URL}/transaction/all`,
	getMyTransactions: `${APP_URL}/transaction/me`,
	// Send Email
	sendEmail: `${APP_URL}/contact`,
	// Notice
	getNotice: `${APP_URL}/notices`,
	// QUESTIONS
	getQuestions: `${APP_URL}/questions`,
	// feedBack
	// feedBack: `${APP_URL}/feedbacks`,
};
