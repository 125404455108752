import style from "./ParaGraph.module.scss";

function ParaGraph({ heading }) {
	return (
		<div className={style.container2}>
			<h2>{heading}</h2>
			<div className={style.details}>
				<div className={style.desc2}>
					<b>Dollar Buy Sell Corner</b> is a Digital dollar buy sell Solutions
					For Freelancer, We provide a secured transaction system where personal
					privacy is also protected. You can Exchange, Receive, Send and accept
					online payment easily on your personal account. We work only for
					Freelancers, Outsourcer, and Online workers who need online payment
					solutions. We do not support any Betting, Gambling, and illegal web
					payments. If you are searching for a trusted dollar buy sell website
					in bangladesh then our website will be a great agent for you. Because
					We are the fastest online dollar buy sell bd website. Many exchanges
					use the same system to exchange their client's money. We have built
					our own system from scratch. We have developed relations with
					e-currency providers to enable a cutting edge speed of dollar buy sell
					exchange. I would like to remind again that We work only for
					Freelancers, Outsourcer, and Online workers who need online payment
					solutions for dollar buy sell. We do not support any Betting,
					Gambling, and illegal web payments.
					<b>
						Your FEEDBACK is very important for us . Please stay tuned we will
						add feedBack option soon.{" "}
					</b>
					thanks.
				</div>
			</div>
		</div>
	);
}

export default ParaGraph;
