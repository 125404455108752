import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Helmet } from "react-helmet";
import { API } from "../../../Assets/API";
import { getGateWay } from "../../../redux/transaction/transactionAction";
// Styles
import style from "./Rates.module.scss";
import dataImage from "../../../Assets/Exchange/data.png";
// Components
// import AuthGuard from "../../../components/AuthGuard/AuthGuard";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import RateTable from "../../../components/RateTable/RateTable";
import TableDataLoading from "../../../components/TransactionTableForAdmin/TableDataLoading";

function Rates() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		setLoading(true);

		axios
			.get(API.getGateway)
			.then((gateway) => {
				dispatch(getGateWay(gateway.data.data));
				setData(gateway.data.data);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}, [dispatch]);

	return (
		<>
			<Helmet>
				<title>RATES | Dollar Buy Sell Corner</title>
				{/* <meta name="title" content="RATES | Dollar Buy Sell Corner" />
				<meta
					name="description"
					content="Dollar Buy Sell Corner is a  trusted dollar buy sell service "
				/>
				<meta
					name="keywords"
					content="dollar buy sell, dollar buy sell bd, usd to bd, bd dollar buy sell,  "
				/> */}
			</Helmet>
			<Navbar />
			{/* <AuthGuard logged="no" roleActive={false}></AuthGuard> */}
			<div className={style.rates}>
				<h1>Exchange Rates</h1>

				{/* Data Table */}
				<div className={style.tableWrapper}>
					<div className={style.table}>
						{loading ? (
							<>
								{Array(11)
									.fill("")
									.map(() => (
										<TableDataLoading key={Math.random()} />
									))}
							</>
						) : data.length === 0 ? (
							<div className={style.emptyBlock}>
								<img src={dataImage} alt="Data error" />
								<div className={style.text}>
									<h2>Empty Data Table</h2>
									<p>
										Transaction Table is empty. It could be network issue.
										Please, reload the page and and try again.
									</p>
								</div>
							</div>
						) : (
							<RateTable data={data} />
						)}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Rates;
