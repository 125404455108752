import style from "./TransationDetail.module.scss";
import { AiOutlineClose } from "react-icons/ai";
import { FiUser, FiDollarSign } from "react-icons/fi";
import { BsArrowReturnRight, BsArrowReturnLeft } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { processDate } from "../../utils/processDate";

function TransationDetail({ data, setOpen }) {
	return (
		<div className={style.transationDetail}>
			<div className={style.wrapper} onClick={() => setOpen(false)}>
				<div className={style.info} onClick={(e) => e.stopPropagation()}>
					<div className={style.info__content}>
						{/* Close button */}
						<div
							className={style.closeButton}
							onClick={() => {
								setOpen(false);
							}}
						>
							<AiOutlineClose />
						</div>
						<h2>Transaction Info.</h2>
						<p
							className={
								data.status === "Pending"
									? style.orange
									: data.status === "Processing"
									? style.violet
									: data.status === "Rejected" || data.status === "Canceled"
									? style.red
									: data.status === "Hold"
									? style.blue
									: data.status === "Completed"
									? style.green
									: style.default
							}
						>
							{data.status}
						</p>

						{/* Info */}
						<div className={style.blocks}>
							<div className={style.blockGroup}>
								{/* user */}
								<div className={style.block}>
									<div className={style.block__logo}>
										<FiUser />
									</div>
									<div className={style.block__name}>
										<p>User</p>
										<h5>{data.user_name}</h5>
									</div>
								</div>
								{/* time */}
								<div className={style.block}>
									<div className={style.block__logo}>
										<BiTime />
									</div>
									<div className={style.block__name}>
										<p>Time</p>
										<h5>
											{processDate(data.date_time).date} |{" "}
											{processDate(data.date_time).time}
										</h5>
									</div>
								</div>
							</div>

							<div className={style.blockGroup}>
								{/* sent */}
								<div className={style.block}>
									<div className={style.block__logo}>
										<BsArrowReturnRight />
									</div>
									<div className={style.block__name}>
										<p>Sent</p>
										<h5>{data.sent}</h5>
									</div>
								</div>
								{/* received */}
								<div className={style.block}>
									<div className={style.block__logo}>
										<BsArrowReturnLeft />
									</div>
									<div className={style.block__name}>
										<p>Received</p>
										<h5>{data.received}</h5>
									</div>
								</div>
							</div>

							<div className={style.blockGroup}>
								{/* sent amount */}
								<div className={style.block}>
									<div className={style.block__logo}>
										{data.sent_unit === `\\u0024` ? "$" : "৳"}
										{/* <FiDollarSign /> */}
									</div>
									<div className={style.block__name}>
										<p>Sent Amount</p>
										<h5>{data.amount}</h5>
									</div>
								</div>
								{/* received amount */}
								<div className={style.block}>
									<div className={style.block__logo}>
										{data.received_unit === `\\u0024` ? "$" : "৳"}
										{/* <FiDollarSign /> */}
									</div>
									<div className={style.block__name}>
										<p>Received Amount</p>
										<h5>{data.received_amount}</h5>
									</div>
								</div>
							</div>

							{/* ///////////////////////////////////// */}
							{/* ADDITIONAL */}
							{/* ///////////////////////////////////// */}
							<h4>Additional Information</h4>
							<div className={style.blockGroup}>
								<div className={style.sinfo}>
									<div>
										<small>{data.sent} Account Number</small>
									</div>
									<p>{data.from_account ? data.from_account : "N/A"}</p>
								</div>
								<div className={style.sinfo}>
									<div>
										<small>{data.received} Account Number</small>
									</div>
									<p>{data.to_account ? data.to_account : "N/A"}</p>
								</div>
							</div>
							<div className={style.blockGroup}>
								<div className={style.sinfo}>
									<div>
										<small>Transaction Id / Batch</small>
									</div>
									<p>{data.transaction_id ? data.transaction_id : "N/A"}</p>
								</div>
								<div className={style.sinfo}>
									<div>
										<small>Contact</small>
									</div>
									<p>{data.contact_details ? data.contact_details : "N/A"}</p>
								</div>
							</div>
							{/* <div className={style.sinfo}>
                <div><small>Instruction</small></div>
                <p>{data.special_instructions ? data.special_instructions : "N/A"}</p>
              </div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TransationDetail;
