import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
// Styles
import styles from "./Navbar.module.scss";
import { BiGridAlt, BiData } from "react-icons/bi";
import { BsCircleFill } from "react-icons/bs";
import { FiUser, FiLogOut } from "react-icons/fi";
import { AiFillStar, AiOutlineUser } from "react-icons/ai";
import logoImage from "../../Assets/Logo/Exchange-corner.png";
// Components
import DrawerTogglerButton from "./DrawerTogglerButton";
// Actions
import { logoutUser } from "../../redux/user/userAction";

function Nav({ drawerToggleClickHnadler }) {
	const [open, setOpen] = useState(false);
	const user = useSelector((state) => state.user);
	const [online, setOnline] = useState(true);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const stopPropation = (event) => {
		event.stopPropagation();
	};

	// LOGOUT FUNCTION
	const doLogout = () => {
		dispatch(logoutUser);
		setOpen(false);
		// if user is in profile page then navigate to homepage
		if (location.pathname === "/profile") {
			navigate("/");
		}
	};

	// CHECK WHEATHER OPERATOR IS ONLINE OR NOT
	useEffect(() => {
		// var startTime = "9:00:00";
		// var endTime = "11:59:59";
		// var currentDate = new Date();
		// var startDate = new Date(currentDate.getTime());
		// startDate.setHours(startTime.split(":")[0]);
		// startDate.setMinutes(startTime.split(":")[1]);
		// startDate.setSeconds(startTime.split(":")[2]);
		// var endDate = new Date(currentDate.getTime());
		// endDate.setHours(endTime.split(":")[0]);
		// endDate.setMinutes(endTime.split(":")[1]);
		// endDate.setSeconds(endTime.split(":")[2]);
		// var valid = startDate < currentDate && endDate > currentDate;
		// if (valid) {
		// 	setOnline(true);
		// } else {
		// 	setOnline(false);
		// }
	}, []);

	return (
		<div className={styles.nav}>
			<div className={styles.nav__brand}>
				<Link to="/">
					<img src={logoImage} alt="dollarbuysellcorner.img" />
				</Link>
			</div>

			<div className={styles.online}>
				{user.currentUser &&
				(user.currentUser.user_role === 1 ||
					user.currentUser.user_role === 2) ? null : (
					<div className={styles.online__status}>
						<div className={styles.head}>
							<div>
								<AiOutlineUser />
							</div>
							<div>Operator : </div>
						</div>
						<div className={styles.stat}>
							{/* <div className={online ? styles.green : styles.red}><BsCircleFill/></div>
                <div>{online ? <>Online</> : <>Offline</>}</div> */}
							<div className={styles.green}>
								<BsCircleFill />
							</div>
							<div>Online</div>
						</div>
					</div>
				)}

				<div className={styles.online__worktime}>
					{user.currentUser &&
					(user.currentUser.user_role === 1 ||
						user.currentUser.user_role === 2) ? (
						<>
							<span>Working hour :</span> 10:00AM to 2:00AM
						</>
					) : (
						<>
							<span>Worktime :</span> 10:00AM to 2:00AM
						</>
					)}
				</div>
			</div>
			{/* 
			<div className={styles.itemText}>
				Helpline:
				<a href="https://wa.me/+8801729050990" target="_blank" rel="noreferrer">
					+8801729050990
				</a>
			</div> */}

			<div className={styles.nav__links}>
				<NavLink
					className={(navData) => (navData.isActive ? styles.activeLink : ``)}
					to="/"
				>
					Exchange
				</NavLink>
				<NavLink
					className={(navData) => (navData.isActive ? styles.activeLink : ``)}
					to="/rates"
				>
					Rates
				</NavLink>
				{user.currentUser &&
				(user.currentUser.user_role === 1 ||
					user.currentUser.user_role === 2) ? (
					<>
						<NavLink
							className={(navData) =>
								navData.isActive ? styles.activeLink : ``
							}
							to="/admin/transactions"
						>
							User Transactions{" "}
							<span>
								<AiFillStar />
							</span>
						</NavLink>
						<NavLink
							className={(navData) =>
								navData.isActive ? styles.activeLink : ``
							}
							to="/admin/users"
						>
							User List{" "}
							<span>
								<AiFillStar />
							</span>
						</NavLink>
					</>
				) : (
					<>
						<NavLink
							className={(navData) =>
								navData.isActive ? styles.activeLink : ``
							}
							to="/about"
						>
							Home
						</NavLink>
						<NavLink
							className={(navData) =>
								navData.isActive ? styles.activeLink : ``
							}
							to="/contacts"
						>
							Contacts
						</NavLink>
					</>
				)}
				{user.isLogedIn ? (
					<div className={styles.dashboard} onClick={() => setOpen(!open)}>
						<div>
							<BiGridAlt />
						</div>
						<div>Dashboard</div>
						{user.currentUser && user.currentUser.status === "incomplete" ? (
							<div></div>
						) : null}
					</div>
				) : (
					<>
						<NavLink
							className={(navData) =>
								navData.isActive ? styles.activeLink : ``
							}
							to="/login"
						>
							Login
						</NavLink>
						<Link className={styles.dashboard} to="/register">
							Register
						</Link>
					</>
				)}
				<DrawerTogglerButton click={drawerToggleClickHnadler} />
			</div>
			{open ? (
				<div className={styles.menuWrapper} onClick={() => setOpen(false)}>
					<div className={styles.dashboardMenu} onClick={stopPropation}>
						<Link to="/transactions">
							<span>
								<BiData />
							</span>{" "}
							Transactions
						</Link>
						<Link to="/profile">
							<span>
								<FiUser />
							</span>
							Profile
							{user.currentUser && user.currentUser.status === "incomplete" ? (
								<span>
									<BsCircleFill />
								</span>
							) : null}
						</Link>
						<div onClick={doLogout}>
							<span>
								<FiLogOut />
							</span>{" "}
							Logout
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
}

export default Nav;
