import { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
// Assets
import style from "./UserDetail.module.scss";
import gif from "../../Assets/Admin_Dashbosrd/loading.gif";
import { API } from "../../Assets/API";

// User status options
const options = [
  { id: 0, name: "incomplete" },
  { id: 1, name: "pending" },
  { id: 2, name: "approved" },
  { id: 3, name: "rejected" },
  { id: 4, name: "banned" },
];

function StatusChangeButton({ changeStatus, userId }) {
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  //   Change status functions
  const handleMenu = (o) => {
    setLoading(true);
    setOpenMenu(false);

    const data = { status: o.id };
    axios
      .patch(`${API.userDetail}/${userId}`, data)
      .then(() => {
        setLoading(false);
        changeStatus(o.name);
        toast.success("Status Changed successfully.");
      })
      .catch(() => {
        setLoading(false);
        toast.error("Something went wrong !");
      });
  };

  return (
    <div className={style.buttonGroup}>
      <div className={style.select}>
        {/* ================== Selection title ============== */}
        {!loading ? (
          <div className={style.select__title} onClick={(e) => setOpenMenu(!openMenu)}>
            Change Status
          </div>
        ) : (
          <div className={`${style.select__title} ${style.loading}`}>
            <img src={gif} alt="" />
            <span>Loading</span>
          </div>
        )}

        {/* ================== Selection Options ============== */}
        <div className={ openMenu ? `${style.select__options} ${style.open}` : `${style.select__options}`}>
          {options.map((o) => (
            <div key={o.id} className={style.option} onClick={() => handleMenu(o)}>
              <div className={style.oname}>{o.name}</div>
            </div>
          ))}
        </div>
        
      </div>
    </div>
  );
}

export default StatusChangeButton;
