import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BsChevronDown, BsCircleFill } from "react-icons/bs";
import { API } from "../../Assets/API";
import style from "./OptionButton.module.scss";
import gif from "../../Assets/Admin_Dashbosrd/loading.gif";

function OptionButton({ id, status, setStatus, options, pageRef }) {
  // Menu Open varable
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  // Update status
  const updateStatus = (obj) => {
    setLoading(true);
    const data = { status_id: obj.id };

    axios
      .patch(`${API.updateTransactionStatus}/${id}`, data)
      .then(() => {
        toast.success(`Status updated to ${obj.status}`);
        setStatus(obj);
        setOpenMenu(false);
        setLoading(false);
      })
      .catch(() => {
        toast.error("Something Went Wrong !");
        setOpenMenu(false);
        setLoading(false);
      });
  };

  //   Handle select item functionalities
  const handleMenu = (obj) => {
    updateStatus(obj);
  };

  useEffect(() => {
    if (pageRef && pageRef.current) {
      pageRef.current.addEventListener("click", () => {
        setOpenMenu(false);
      });
    }
  }, [pageRef]);

  return (
    <div className={style.buttonGroup}>
      <div className={style.select}>
        {/* ================== Selection title ============== */}
        <div
          className={style.select__title}
          onClick={() => setOpenMenu(!openMenu)}
        >
          {loading ? (
            <div>
              <img src={gif} alt=""/>
              Loading..
            </div>
          ) : (
            <div>
              <span
                className={
                  status.id === 1
                    ? style.orange
                    : status.id === 2
                    ? style.violet
                    : status.id === 3 || status.id === 4
                    ? style.red
                    : status.id === 6
                    ? style.green
                    : status.id === 5
                    ? style.blue
                    : style.default
                }
              >
                <BsCircleFill />
              </span>
              {status.status}
            </div>
          )}
          <div>
            <BsChevronDown />
          </div>
        </div>

        {/* ================== Selection Options ============== */}
        <div
          className={
            openMenu
              ? `${style.select__options} ${style.open}`
              : `${style.select__options}`
          }
        >
          {options &&
            options.map((o) => (
              <div
                key={o.id}
                className={style.option}
                onClick={() => handleMenu(o)}
              >
                <div className={style.ocheck}>
                  {status.id === o.id ? (
                    <span
                      className={
                        o.id === 1
                          ? style.orange
                          : o.id === 2
                          ? style.violet
                          : o.id === 3 || o.id === 4
                          ? style.red
                          : o.id === 6
                          ? style.green
                          : o.id === 5
                          ? style.blue
                          : style.default
                      }
                    >
                      <BsCircleFill />
                    </span>
                  ) : null}
                </div>
                <div className={style.oname}>{o.status}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default OptionButton;
