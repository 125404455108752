const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];


export const processDate = (value) => {
    let date = value.split("T")[0];
    let time = value.split("T")[1];

    let dd = date.split("-")[2];
    let mm = Number(date.split("-")[1]);
    let yy = date.split("-")[0];

    let h = Number(time.split(":")[0]);
    let m = Number(time.split(":")[1]);
    let t = "";

    if(h === 0){
        h = 12;
        t = "AM";
    }
    else if(h > 12){
        h = h % 12;
        if(h === 0){
            h = 12;
        }
        t = "PM";
    }
    else{
        t = "AM";
    }


    let formatedDate = `${dd} ${months[mm - 1]} ${yy}`;
    let formatedTime = `${h}:${m} ${t}`;

    return {
        date: formatedDate,
        time: formatedTime,
    };
}