import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
// Redux Action
import {
	getGateWay,
	getRates,
	setHash,
} from "../../../redux/transaction/transactionAction";
import { logoutUser } from "../../../redux/user/userAction";
// Styles & Images
import style from "./Exchange.module.scss";
import { BiErrorCircle } from "react-icons/bi";
import { BsFillCircleFill } from "react-icons/bs";
import dataImage from "../../../Assets/Exchange/data.png";
import loginImage from "../../../Assets/Exchange/login.png";
import idImage from "../../../Assets/Exchange/id.png";
import cmpltImage from "../../../Assets/Exchange/completed.png";
import { AiFillStar } from "react-icons/ai";
// Components
// import Rates from "../Rates/Rates";
import AuthGuard from "../../../components/AuthGuard/AuthGuard";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import Dropdown from "../../../components/Dropdown/Dropdown";
import PopUp from "../../../components/PopUp/PopUp";
import ExchangeDesclaimer from "../../../components/ExchangeDesclaimer/ExchangeDesclaimer";
import ParaGraph from "../../../components/ParaGraph/ParaGraph";
// Higher-order components
import {
	ExchangeWrapper,
	FormWrapper,
	MessageWrapper,
} from "../../../components/ExchangeWrapper/ExchangeWrapper";
// API
import { API } from "../../../Assets/API";
import ExchangeFomLoader from "../../../components/ExchangeFormLoader/ExchangeFomLoader";
import TransactionTableGlobal from "../../../components/TransactionTable/TransactionTableGlobal";
import toast from "react-hot-toast";

function Exchange() {
	const [sender, setSender] = useState({});
	const [receiver, setReceiver] = useState({});
	const [sendAmount, setSendAmount] = useState(1);
	const [receivedAmount, setreceivedAmount] = useState(0);
	const [popUp, setPopUp] = useState("");
	const [globalError, setGlobalError] = useState("");
	const [rangeError, setRangeError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dataLoading, setDataLoading] = useState(false);
	const rates = useSelector((state) => state.transaction.rates);
	const gateway = useSelector((state) => state.transaction.gateway);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const pageRef = useRef(null);

	// Calculation
	const calculation = (value) => {
		setRangeError(false);
		setGlobalError("");
		setSendAmount(value);

		if (Object.keys(sender).length > 0 && Object.keys(receiver).length > 0) {
			// 2. Calculate the price
			let calculated_price;

			for (let i = 0; i < rates.length; i++) {
				const rate = rates[i];
				const from = Number(rate.slug.split("_")[0]);
				const to = Number(rate.slug.split("_")[1]);

				if (from === sender.id && to === receiver.id) {
					calculated_price = (value / rate.rate).toFixed(2);
					break;
				}
			}

			// 3. If calculated price greater than reserved show error
			if (calculated_price > receiver.reserved_amount) {
				setRangeError(true);
			} else {
				setreceivedAmount(calculated_price);
			}
		}
	};

	// Clear all fields
	const clearAllFields = () => {
		setSender({});
		setReceiver({});
		setSendAmount(0);
		setreceivedAmount(0);
	};

	// Validation
	const validation = () => {
		if (Object.keys(sender).length > 0 && Object.keys(receiver).length > 0) {
			if (sender.id === receiver.id) {
				toast.error("Sender and receiver couldn't be same.");
				return false;
			}
			if (sendAmount > 0) {
				return true;
			} else {
				setGlobalError("Your amount should be greater than 0.");
				return false;
			}
		} else {
			setGlobalError("Please select sender & receiver.");
			return false;
		}
	};

	// Form submission functionalitiesf
	const handleForm = (e) => {
		e.preventDefault();

		if (validation()) {
			if (user.isLogedIn) {
				if (user.currentUser.status !== "incomplete") {
					setGlobalError("");
					setLoading(true);

					var data = new FormData();
					data.append("from", sender.id);
					data.append("to", receiver.id);
					data.append("sent", sendAmount);
					data.append("received", receivedAmount);

					axios
						.post(API.newTransaction, data)
						.then((res) => {
							const hash = res.data.data.hash_id;
							dispatch(setHash(hash));
							clearAllFields();
							navigate(`/transaction/${hash}`);
							setLoading(false);
						})
						.catch((err) => {
							if (err.response.data.code === 401) {
								setPopUp("auth error");
								dispatch(logoutUser);
							}
							if (err.response.data.errors.to) {
								setGlobalError(err.response.data.errors.to);
							} else if (err.response.data.errors.from) {
								setGlobalError(err.response.data.errors.from);
							} else if (err.response.data.errors.received) {
								setGlobalError(err.response.data.errors.received);
							}
							setLoading(false);
						});
				} else {
					setPopUp("varification error");
				}
			} else {
				setPopUp("login error");
			}
		}
	};

	// Call the data fetching api when reload the page
	useEffect(() => {
		if (rates.length === 0 || gateway.length === 0) {
			setDataLoading(true);
			axios
				.get(API.getRates)
				.then((rates) => {
					axios
						.get(API.getGateway)
						.then((gateway) => {
							dispatch(getRates(rates.data.data));
							dispatch(getGateWay(gateway.data.data));
							setSender(gateway.data.data[0]);
							setReceiver(gateway.data.data[3]);
							calculation(1);
							setDataLoading(false);
						})
						.catch(() => {
							setDataLoading(false);
							setPopUp("network error");
						});
				})
				.catch(() => {
					setPopUp("network error");
				});
		} else {
			if (
				Object.keys(sender).length === 0 &&
				Object.keys(receiver).length === 0
			) {
				setSender(gateway[0]);
				setReceiver(gateway[3]);
				calculation(1);
			}
		}
	}, [dispatch, rates.length, gateway.length, sender, receiver]);

	useEffect(() => {
		if (sendAmount > 0) {
			calculation(sendAmount);
		}
	}, [sendAmount, sender, receiver]);

	return (
		<>
			<Navbar />
			<Helmet>
				<title>
					Dollar buy sell | Trusted dollar buy sell bd | Dollar Buy Sell Corner
				</title>
				<meta
					name="title"
					content="Dollar buy sell | Trusted dollar buy sell bd | Dollar Buy Sell Corner"
				/>
				<meta
					name="description"
					content="Dollar Buy Sell Corner is a online trusted dollar buy sell service that provides a fast & secure transaction of skrill, pyypl, perfect money, web money, USDT"
				/>
				<meta
					name="keywords"
					content="dollar buy sell, dollar buy sell bd, usd to bd, bd dollar buy sell,  pyypl dollar buy sell bd, trusted dollar buy sell site in bd, usd buy sell,  skrill dollar buy sell bd, dollar buy and sell bd,  trusted dollar buy sell in bangladesh"
				/>
			</Helmet>
			{/* <AuthGuard logged="no" roleActive={false}></AuthGuard> */}
			<div className={style.exchange} ref={pageRef}>
				<ExchangeWrapper>
					<FormWrapper heading={`Start Exchange`} headingText={``}>
						{/* notice */}
						<div className={style.hpNotice}>
							<p>
								{/* <span>
									<AiFillStar />
									<AiFillStar />
								</span>
								<b>Dollar Buy Sell Corner</b>, <b>Dollar Exchange Corner</b> এবং{" "}
								<b>Exchange Corner BD</b> এই তিনটি website এ আমরা Service দিয়ে
								থাকি।
							</p>
							<p> */}
								<span>
									<AiFillStar /> <AiFillStar />
								</span>
								Customer কে সকল <b>Transactions Fee | Send Money Fee</b> বহন
								করতে হবে।
							</p>
						</div>
						{/* form */}
						{!dataLoading ? (
							<div className={style.exchange__form}>
								<form>
									<div className={style.inputGroup}>
										{/* sender selection */}
										<Dropdown
											label="Send"
											placeholder="Choose sender"
											options={gateway}
											prevValue={receiver}
											value={sender}
											setValue={setSender}
											pageRef={pageRef}
										/>
										{/* reciever selection */}
										<Dropdown
											label="Received"
											placeholder="Choose receiver"
											options={gateway}
											prevValue={sender}
											value={receiver}
											setValue={setReceiver}
											pageRef={pageRef}
										/>
									</div>

									{/* Amount selection */}
									<div className={style.calculation}>
										<div className={style.calc_50}>
											<label>
												<b>Your send amount</b>
											</label>
											<input
												type="text"
												placeholder="Enter your amount"
												value={sendAmount}
												onChange={(e) => calculation(e.target.value)}
											/>
										</div>

										{/* Calculated Amount */}
										<div className={style.calc_50}>
											<div
												className={
													rangeError
														? `${style.calc_amount} ${style.rangeError}`
														: `${style.calc_amount}`
												}
											>
												<h4>
													<b>Received Amount</b>
												</h4>
												<br />
												<h1>
													<span>{receiver.unit === "u0024" ? `$` : `৳`}</span>
													{receivedAmount ? receivedAmount : `0`}
												</h1>
												<p>
													<span>
														<BsFillCircleFill />
													</span>
													Reserved : {}
													<span>
														{receiver.reserved_amount
															? receiver.unit === "u0024"
																? `$ ${receiver.reserved_amount}`
																: `৳ ${receiver.reserved_amount}`
															: receiver.unit === "u0024"
															? `$ 0`
															: `৳ 0`}
														<br />
													</span>
												</p>
												<div className={style.circle}></div>
												<div className={style.circle2}></div>
											</div>
											{rangeError ? (
												<small>
													<span>
														<BiErrorCircle />
													</span>
													Calculated amount according to given amount is greater
													than the reserved.
												</small>
											) : null}
											{globalError.length > 0 ? (
												<small>
													<span>
														<BiErrorCircle />
													</span>
													{globalError}
												</small>
											) : null}
										</div>
									</div>

									{/* Button */}
									{loading ? (
										<button>Loading...</button>
									) : (
										<button onClick={handleForm}>Start Exchange</button>
									)}
								</form>
							</div>
						) : (
							<ExchangeFomLoader />
						)}
					</FormWrapper>

					<MessageWrapper>
						<ExchangeDesclaimer
							heading={`বিসমিল্লাহির রাহমানির রাহিম`}
							headingText={`You will be able to transact subject to the following conditions.`}
						/>
					</MessageWrapper>
				</ExchangeWrapper>
				{/* feedBack */}
				{/* <feedBack /> */}

				{/* table - global */}
				<TransactionTableGlobal />
				{/* ParaGraph */}
				<ParaGraph
					heading={`About US`}
					headingText={`You will be able to transact subject to the following conditions.`}
				/>
				{/* <Rates /> */}

				{/* <feedBack /> */}
			</div>

			<Footer />
			{popUp === "network error" ? (
				<PopUp
					image={dataImage}
					heading={`Network Error`}
					text={`We can't fetch important data due to network issue.`}
					offPopUp={setPopUp}
					btnText={``}
					link={``}
				/>
			) : popUp === "login error" ? (
				<PopUp
					image={loginImage}
					heading={`You need to login first`}
					text={` Please login or signup to be able to make transaction.`}
					offPopUp={setPopUp}
					btnText={`Signin`}
					link={`/login`}
				/>
			) : popUp === "varification error" ? (
				<PopUp
					image={idImage}
					heading={`Verify your NID`}
					text={` Please upload your NID image and verify yourself to make transaction.`}
					offPopUp={setPopUp}
					btnText={`Click Here`}
					link={`/profile`}
				/>
			) : popUp === "auth error" ? (
				<PopUp
					image={loginImage}
					heading={`Authentication error`}
					text={`Your authentication token expired! please login and try again.`}
					offPopUp={setPopUp}
					btnText={`Signin`}
					link={`/login`}
				/>
			) : popUp === "transaction completed" ? (
				<PopUp
					image={cmpltImage}
					heading={`Transaction completed!`}
					text={`Thank you for being with us. To track your transaction please go to your transaction page.`}
					offPopUp={setPopUp}
					btnText={``}
					link={``}
				/>
			) : null}
		</>
	);
}

export default Exchange;
