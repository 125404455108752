import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
// Styles, icons and images
import { AiFillStar } from "react-icons/ai";
import styles from "./Signin.module.scss";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import line from "../../../Assets/Signin/line.png";
// Components
import Home from "../Home/Home";
import Navbar from "../../../components/Navbar/Navbar";
// import Footer from "../../../components/Footer/Footer";
import WelcomeSlide from "../../../components/WelcomeSlide/WelcomeSlide";
// Redux
import { getUserInfo, loginUser } from "../../../redux/user/userAction";
import AuthGuard from "../../../components/AuthGuard/AuthGuard";
import { connect, useDispatch } from "react-redux";

function Signin({ errorMessage, loginUser }) {
	const [watch, setWatch] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// INITIAL VALUES
	const initVals = {
		email: "",
		password: "",
	};

	// VALIDATION SCHEMA
	const signinSchema = Yup.object().shape({
		email: Yup.string().required("Email is required!"),
		password: Yup.string()
			.min(6, "Password must be 6 or more character long.")
			.required("Password is required!"),
	});

	// ON-SUBMIT HANDLER
	const onSubmitHandeler = async (values) => {
		// 1. set loading and clear error message
		setLoading(true);
		// 2. call loginUser function to login
		let isLoggedin = await loginUser(values.email, values.password);
		// 3. If user can successfully log in just navigate user to home page else show error message
		if (isLoggedin) {
			setLoading(false);
			dispatch(getUserInfo);
			toast.success("Logged in successfully");
			navigate("/");
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<Helmet>
				<title>log in | Dollar Buy Sell Corner</title>
				{/* <meta name="title" content="Log in | Dollar Buy Sell Corner" />
				<meta
					name="description"
					content="Welcome to Dollar Buy Sell Corner. This is a website about finding good deals and selling your items for the best price possible."
				/> */}
			</Helmet>
			<Navbar />
			<AuthGuard logged="yes" roleActive={false}>
				<div className={styles.signin}>
					<div className={styles.signin__form}>
						<h1>
							Welcome to
							<br /> Dollar Buy Sell Corner !
						</h1>

						<p>
							<span>
								<AiFillStar /> <AiFillStar />
							</span>
							Please Login to get access our services. For more details please
							Scroll down.
						</p>

						<Formik
							initialValues={initVals}
							validationSchema={signinSchema}
							onSubmit={(values) => onSubmitHandeler(values)}
						>
							{({ errors, touched }) => (
								<Form>
									{/* ============ Email Fields ============ */}
									<div className={styles.inputGroup}>
										<label htmlFor="email">Email</label>
										<Field
											name="email"
											placeholder="Enter your email"
											type="email"
											className={
												(errorMessage && errorMessage.email) ||
												(errors.email && touched.email)
													? styles.haveerror
													: ``
											}
										/>
										{/* ============ Error Message ============ */}
										<small>
											<ErrorMessage name="email" />
											{errorMessage && errorMessage.email && errorMessage.email}
										</small>
									</div>
									{/* ============ Email Fields ============ */}
									<div className={styles.inputGroup}>
										<label htmlFor="password">Password</label>
										<Field
											name="password"
											placeholder="Enter your password"
											type={watch ? "text" : "password"}
											className={
												(errorMessage && errorMessage.password) ||
												(errors.password && touched.password)
													? styles.haveerror
													: ``
											}
										/>
										<div className={styles.eye}>
											{watch ? (
												<span onClick={() => setWatch(false)}>
													<AiOutlineEye />
												</span>
											) : (
												<span onClick={() => setWatch(true)}>
													<AiOutlineEyeInvisible />
												</span>
											)}
										</div>
										<small>
											<ErrorMessage name="password" />
											{errorMessage &&
												errorMessage.password &&
												errorMessage.password}
										</small>
									</div>
									{/* Error Message */}
									{/* <small className={styles.errorMessage}>{error}</small> */}
									{/* ============ Submit Button ============ */}
									{loading ? (
										<button type="submit" disabled>
											Loading ...
										</button>
									) : (
										<button type="submit">Log in</button>
									)}
								</Form>
							)}
						</Formik>
						<p className={styles.lastText}>
							Not a Member? <Link to="/register">Registration Now</Link>
						</p>
					</div>
					<div className={styles.signin__style}>
						<div className={styles.wrapper}>
							<WelcomeSlide />
							<img src={line} alt="line1" className={styles.line1} />
							<img src={line} alt="line2" className={styles.line2} />
						</div>
					</div>
				</div>
			</AuthGuard>
			<Home />
		</>
	);
}

const mapStateToProps = (state) => ({
	errorMessage: state.user.failedMessageForLogin,
});

export default connect(mapStateToProps, { loginUser })(Signin);
