import { Link } from "react-router-dom";
import style from "./PageNotFound.module.scss";
import sad from "../../Assets/Global/sad.png";

function PageNotFound() {
  return (
    <div className={style.pagenotfound}>
      <div className={style.pagenotfound__wrapper}>
        <div className={style.emoji}>
          <img src={sad} alt="" />
        </div>
        <div className={style.text}>
          <h1>404</h1>
          <h2>OOPS! PAGE NOT FOUND</h2>
          <p>
            Sorry but the page you are looking for does not exist, have been
            removed. name changed or is temporarily unavailable
          </p>
          <Link to="/">Back to home</Link>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
