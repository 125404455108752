import {
  LOGIN_SUCCESS,
  SET_AUTHENTICATED,
  SET_USER,
  LOGOUT_SUCCESS,
  LOGIN_FAILED,
  CLEAR_MESSAGE,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILED,
} from "./userType";

// INITIAL STATE
const initialState = {
  isLogedIn: false,
  userToken: "",
  currentUser: null,
  failedMessageForLogin: {},
  failedMessageForRegistration: {}
};

// USER REDUCER
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_MESSAGE:
      return {
        failedMessage: "",
        failedMessageForRegistration: {}
      };
    case REGISTRATION_SUCCESS:
      return{

      }
    case REGISTRATION_FAILED:
      return{
        ...state,
        failedMessageForRegistration: action.errors,
      }
    case LOGIN_SUCCESS:
      localStorage.setItem("userToken", action.user.data.token);
      return {
        ...state,
        userToken: action.user.data.token,
        isLogedIn: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        failedMessageForLogin: action.errors,
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem("userToken");
      return {
        isLogedIn: false,
        userToken: "",
        currentUser: null,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        userToken: action.token,
        isLogedIn: true,
      };
    case SET_USER:
      return {
        ...state,
        currentUser: action.user,
      };
    default:
      return state;
  }
};

export default userReducer;
