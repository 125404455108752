import { Link } from "react-router-dom";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";
import { Helmet } from "react-helmet";

// Styles
import "react-accessible-accordion/dist/fancy-example.css";
import style from "./Home.module.scss";
// Images
import bannerImage from "../../../Assets/Home/bannerImage.png";
import whyImage from "../../../Assets/Home/whyImage.png";
import question from "../../../Assets/Home/Question_Flatline.png";
// Icons
import { BsArrowRight } from "react-icons/bs";
import { FiActivity } from "react-icons/fi";
import { BiTime, BiLineChart } from "react-icons/bi";
// Components
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../../Assets/API";

function Home() {
	const user = useSelector((state) => state.user);
	const [questions, setQuestions] = useState([]);

	useEffect(() => {
		axios
			.get(API.getQuestions)
			.then((res) => {
				setQuestions(res.data.data);
			})
			.catch(() => {});
	}, []);

	return (
		<>
			<Helmet>
				<title>Home | Dollar Buy Sell | Dollar Buy Sell Corner</title>
				{/* <meta
					name="title"
					content="Home | Dollar Buy Sell | Dollar Buy Sell Corne"
				/>
				<meta
					name="description"
					content="Welcome to the Dollar Buy Sell Corner most trusted dollar buy sell exchanger in Bangladesh."
				/>
				<meta
					name="keywords"
					content="dollar buy sell, dollar exchange, dollar exchange bd, dollar buy sell bd, dollar buy sell bangladesh, dollar sell bd, skrill dollar buy sell bd, usd buy sell, dollar buy sell website, dollar buy sell in bangladesh"
				/> */}
			</Helmet>
			<Navbar />

			<div className={style.home}>
				{/* /////////////////////////////////////////// banner /////////////////////////////////////////// */}
				<section className={style.home__banner}>
					<div className={style.bannerText}>
						<div className={style.textWrapper}>
							<h1 className={style.bigHeading}>
								A Digital Solutions For Freelancer
							</h1>
							<p className={style.headingText}>
								We provide a secured transaction system where personal privacy
								is also protected. We work only for Freelancers, Outsourcer, and
								Online workers who need online payment solutions. We do not
								support any Betting, Gambling, and illegal web payments.
							</p>
							{user && user.isLogedIn ? (
								<Link to="/" className={style.roundStyleLink}>
									<div className={style.icon}>
										<BsArrowRight />
									</div>
									<div className={style.text}>Start Exchange now</div>
								</Link>
							) : (
								<Link to="/register" className={style.roundStyleLink}>
									<div className={style.icon}>
										<BsArrowRight />
									</div>
									<div className={style.text}>Registration now</div>
								</Link>
							)}
							{/* <Link to="/" className={style.roundStyleLink}>
								<div className={style.icon}>
									<BsArrowRight />
								</div>
								<div className={style.text}>Start Exchange</div>
							</Link> */}
						</div>
					</div>
					<div className={style.bannerImage}>
						<img src={bannerImage} alt="Money-jar" />
					</div>
				</section>

				{/* /////////////////////////////////////////// why /////////////////////////////////////////// */}
				<section className={style.home__why}>
					<div className={style.whyImage}>
						<img src={whyImage} alt="timer" />
					</div>
					<div className={style.whyText}>
						<div className={style.textWrapper}>
							<h3 className={style.coloredHeading}>Why choose us?</h3>
							<h1 className={style.bigHeading}>We are fast</h1>
							<p className={style.headingText}>
								We are the fastest. Many exchanges use the same system to
								exchange their client's money. We have built our own system from
								scratch. We have developed relations with e-currency providers
								to enable a cutting edge speed of exchange.
							</p>
							{user && user.isLogedIn ? (
								<Link to="/" className={style.roundStyleLink}>
									<div className={style.icon}>
										<BsArrowRight />
									</div>
									<div className={style.text}>Start Exchange now</div>
								</Link>
							) : (
								<Link to="/register" className={style.roundStyleLink}>
									<div className={style.icon}>
										<BsArrowRight />
									</div>
									<div className={style.text}>Registration now</div>
								</Link>
							)}
							{/* <Link to="/" className={style.roundStyleLink}>
								<div className={style.icon}>
									<BsArrowRight />
								</div>
								<div className={style.text}>Start Exchange</div>
							</Link> */}
						</div>
					</div>
				</section>

				{/* ///////////////////////////////////////// service //////////////////////////////////////// */}
				<section className={style.home__offer}>
					<div className={style.offerText}>
						<h3 className={style.coloredHeading}>Our Services</h3>
						<h1 className={style.bigHeading}>What We Offer</h1>
						<p className={style.headingText}>
							We possibly offer the best exchange rate in any website. If you
							buy or sell high amount regularly then we will consider the rate
							that we offer in the website.
						</p>
						{user && user.isLogedIn ? (
							<Link to="/" className={style.roundStyleLink}>
								<div className={style.icon}>
									<BsArrowRight />
								</div>
								<div className={style.text}>Start Exchange now</div>
							</Link>
						) : (
							<Link to="/register" className={style.roundStyleLink}>
								<div className={style.icon}>
									<BsArrowRight />
								</div>
								<div className={style.text}>Registration now</div>
							</Link>
						)}
						{/* <Link to="/" className={style.roundStyleLink}>
							<div className={style.icon}>
								<BsArrowRight />
							</div>
							<div className={style.text}>Start Exchange</div>
						</Link> */}
					</div>
					<div className={style.offerOffer}>
						<div className={style.group}>
							<div className={style.offer}>
								<h3>Best rate on the market</h3>
								<p>
									<span>
										<FiActivity />
									</span>
									2.5%
								</p>
							</div>
							<div className={style.offer}>
								<h3>Low transaction fees</h3>
								<p>
									<span>
										<FiActivity />
									</span>
									1.8%
								</p>
							</div>
						</div>
						<div className={style.group}>
							<div className={style.offer}>
								<h3>High exchange limits</h3>
								<p>
									<span>
										<BiLineChart />
									</span>
									500$
								</p>
							</div>
							<div className={style.offer}>
								<h3>Fast transactions confirmation</h3>
								<p>
									<span>
										<BiTime />
									</span>
									25%
								</p>
							</div>
						</div>
					</div>
				</section>

				{/* /////////////////////////////////////////// faq /////////////////////////////////////////// */}
				<section className={style.home__faq}>
					<div className={style.faqImage}>
						<img src={question} alt="q" />
					</div>
					<div className={style.faqContent}>
						<h3 className={style.coloredHeading}>FAQ</h3>
						<h1 className={style.bigHeading}>Frequently Asked Questions</h1>

						<Accordion className={style.accor}>
							{questions &&
								questions.map((question) => (
									<AccordionItem className={style.accor__item}>
										<AccordionItemHeading>
											<AccordionItemButton className={style.accor__item__acb}>
												{question.question}
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel className={style.accor__item__text}>
											<p>{question.answer}</p>
										</AccordionItemPanel>
									</AccordionItem>
								))}
						</Accordion>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
}

export default Home;
