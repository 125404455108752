import { BiInfoCircle } from "react-icons/bi";
import style from "./NidUploadInstruction.module.scss";

function NidUploadInstruction() {
  return (
    <div className={style.message}>
      <h4>
        <div><BiInfoCircle /></div>
        <div>Identity Upload Troubleshooting</div>
      </h4>
      <p>
        If you are having difficulty successfully uploading a passport, driver's
        license or state ID, please ensure the following:
      </p>
      <ul>
        <li>The image is clear and fully visible</li>
        <li>The image is bright and well lit</li>
        <li>There is no glare on the document</li>
      </ul>
    </div>
  );
}

export default NidUploadInstruction;
