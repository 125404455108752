import bkash from "./Exchange/bkash.png";
import nogod from "./Exchange/nogod.png";
import rocket from "./Exchange/rocket.png";
import perfectMoney from "./Exchange/perfectMoney.png";
import skrill from "./Exchange/skrill.png";
import tron from "./Exchange/tron-trx.png";
import astro from "./Exchange/astro-pay.png";
import wm from "./Exchange/web-money.png";
import payeer from "./Exchange/payeer.jpg";
import mg from "./Exchange/money-go.png";
import tether from "./Exchange/tether.png";
import sticpay from "./Exchange/sticpay.png";
import airtm from "./Exchange/airtm.png";
import adv from "./Exchange/adv.png";
import pyypl from "./Exchange/pyypl.png";
import payoneer from "./Exchange/payoneer.png";
import upay from "./Exchange/upay.png";
import bkashA from "./Exchange/bkash-agent.png";
import bep20 from "./Exchange/bep20.png";
import jeton from "./Exchange/jeton.png";
import coinbase from "./Exchange/coinbase.jpg";
import netteller from "./Exchange/netteller.png"

const Logo = [
  bkashA,
  nogod,
  rocket,
  perfectMoney,
  skrill,
  bkash,
  nogod,
  rocket,
  upay,
  wm,
  bep20,
  payoneer,
  pyypl,
  tether,
  tron,
  payeer,
  astro,
  mg,
  sticpay,
  adv,
  airtm,
  jeton,
  coinbase,
  coinbase,
  coinbase,
  netteller,
  netteller,
];

export default Logo;