import axios from "axios";
import { API } from "../../Assets/API";
import {
  LOGIN_SUCCESS,
  SET_USER,
  SET_AUTHENTICATED,
  LOGOUT_SUCCESS,
  LOGIN_FAILED,
  CLEAR_MESSAGE,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILED,
} from "./userType";
import setAuthToken from "../../utils/setAuthToken";

// ============================= USER LOGIN =============================
export const loginUser = (email, password) => async (dispatch) => {
  // 1. process form data
  var body = new FormData();
  body.append("email", email);
  body.append("password", password);

  // 2. clear previous error message
  dispatch({ type: CLEAR_MESSAGE });

  // 3. call api
  try {
    // 4. if login successful save token in local storage and setup auth token for axios headers
    const res = await axios.post(API.login, body);
    setAuthToken(res.data.data.token);
    dispatch({ type: LOGIN_SUCCESS, user: res.data });
    return true;
  } catch (err) {
    // 5. if login failed then setup error message
    dispatch({ type: LOGIN_FAILED, errors: err.response.data.errors });
    return false;
  }
};

// ============================= USER LOGOUT =============================
export const logoutUser = (dispatch) => {
  setAuthToken("");
  dispatch({ type: LOGOUT_SUCCESS });
};

// ========================== USER REGISTRATION ==========================
export const registerUser =
  (email, number, name, password) => async (dispatch) => {
    // 1. process form data
    var body = new FormData();
    body.append("email", email);
    body.append("number", number);
    body.append("name", name);
    body.append("password", password);

    // 2. clear previous error message
    dispatch({ type: CLEAR_MESSAGE });
    // 3. call api
    try {
      // 4. if login successful save token in local storage and setup auth token for axios headers
      const res = await axios.post(API.register, body);
      dispatch({ type: REGISTRATION_SUCCESS, user: res.data });
      return true;
    } catch (err) {
      // 5. if login failed then setup error message
      dispatch({ type: REGISTRATION_FAILED, errors: err.response.data.errors });
      return false;
    }
  };

// ========================== CHECK AUTHENTICATED ==========================
export const checAuthenticated = (dispatch) => {
  const token = localStorage.getItem("userToken");

  if (token) {
    setAuthToken(token);
    dispatch({ type: SET_AUTHENTICATED, token: token });
    return true;
  } else {
    return false;
  }
};

// ========================== GET USER INFORMATION ==========================
export const getUserInfo = (dispatch) => {
  const token = localStorage.getItem("userToken");
  if (token) {
    axios
      .get(API.getUserData)
      .then((res) => {
        dispatch({ type: SET_USER, user: res.data.data });
      })
      .catch((err) => {
        dispatch({ type: LOGOUT_SUCCESS });
      });
  }
};
