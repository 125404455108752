import { useState } from "react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import { API } from "../../Assets/API";
import { useNavigate } from "react-router-dom";
import style from "./ExchangeConfirmForm.module.scss";

function ExchangeConfirmForm({ detail, setPopup }) {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	// initial values
	const initVals = {
		transaction_id: detail.transaction_id ? detail.transaction_id : "",
		sent_account_number: detail.from_account ? detail.from_account : "",
		received_account_number: detail.to_account ? detail.to_account : "",
		contact_details: detail.contact_details ? detail.contact_details : "",
	};

	// validation schema
	const formSchema = Yup.object().shape({
		transaction_id: Yup.string().required("Transaction id is required!"),
		sent_account_number: Yup.string().required(
			"Sent transaction account number is required!"
		),
		received_account_number: Yup.string().required(
			"Received transaction account number is required!"
		),
		contact_details: Yup.string()
			.required("Contact number is required!")
			.min(10, "Contact number should be greater than 10 characters long"),
	});

	//   On submit handler
	const onSubmitHandeler = (values) => {
		setLoading(true);
		const data = new FormData();
		data.append("hash_id", detail.hash_id);
		data.append("transaction_id", values.transaction_id);
		data.append("sent_account_number", values.sent_account_number);
		data.append("received_account_number", values.received_account_number);
		data.append("contact_details", values.contact_details);

		axios
			.post(API.confirmTransaction, data)
			.then(() => {
				setPopup("transaction completed");
				setLoading(false);
				setTimeout(() => {
					navigate("/");
				}, 4000);
			})
			.catch((err) => {
				if (err.response.data.code === 401) {
					setPopup("auth error");
				}
				setLoading(false);
			});
	};

	return (
		<Formik
			initialValues={initVals}
			validationSchema={formSchema}
			onSubmit={(values) => onSubmitHandeler(values)}
			enableReinitialize={true}
		>
			{({ errors, touched }) => (
				<Form>
					{/* ============ Sent Account Number Fields ============ */}
					<div className={style.inputGroup}>
						<label htmlFor="sent_account_number">
							<span>Your {detail.sent}</span> Account Number <span>*</span>
						</label>
						<Field
							name="sent_account_number"
							placeholder={`Enter your ${detail.sent} account number`}
							type="text"
							className={
								errors.sent_account_number && touched.sent_account_number
									? `${style.haveerror}`
									: ``
							}
						/>
						<small>
							<ErrorMessage name="sent_account_number" />
						</small>
					</div>

					{/* ============ Received Account Number Fields ============ */}
					<div className={style.inputGroup}>
						<label htmlFor="received_account_number">
							<span>Your {detail.received}</span> Account Number <span>*</span>
						</label>
						<Field
							name="received_account_number"
							placeholder={`Enter Your ${detail.received} account number`}
							type="text"
							className={
								errors.received_account_number &&
								touched.received_account_number
									? `${style.haveerror}`
									: ``
							}
						/>
						<small>
							<ErrorMessage name="received_account_number" />
						</small>
					</div>

					{/* ============ Transaction Id Fields ============ */}
					<div className={style.inputGroup}>
						<label htmlFor="transaction_id">
							Payment Transaction Id / Batch <span>*</span>
						</label>
						<Field
							name="transaction_id"
							placeholder="Enter your payment transaction id / batch for proof"
							type="text"
							className={
								errors.transaction_id && touched.transaction_id
									? `${style.haveerror}`
									: ``
							}
						/>
						<small>
							<ErrorMessage name="transaction_id" />
						</small>
					</div>

					{/* ============ Contact Number Fields ============ */}
					<div className={style.inputGroup}>
						<label htmlFor="transaction_id">Your Contact Number</label>
						<Field
							name="contact_details"
							placeholder="Enter your Contact number"
							type="text"
							className={
								errors.contact_details && touched.contact_details
									? `${style.haveerror}`
									: ``
							}
						/>
						<small>
							<ErrorMessage name="contact_details" />
						</small>
					</div>

					{/* ============ Submit Button ============ */}
					{loading ? (
						<button type="submit" disabled>
							Loading ...
						</button>
					) : (
						<button type="submit">Confirm Order</button>
					)}
				</Form>
			)}
		</Formik>
	);
}

export default ExchangeConfirmForm;
