import { useState } from "react";
import Dropzone from "react-dropzone";
import style from "./NidUploadForm.module.scss";
import { fileSizeCalculation, stringProcess } from "./ProcessFunc";
import {
	BsCloudUpload,
	BsFillImageFill,
	BsFillCheckCircleFill,
	BsTrash,
} from "react-icons/bs";
import { FiXCircle } from "react-icons/fi";

function NidField({ label, setFile, setError }) {
	const [imageSelected, setImageSelected] = useState(false);
	// const [preview, setPreview] = useState(null);
	const [fileName, setFileName] = useState("");
	const [fileSize, setFileSize] = useState("");
	const [validity, setValidity] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");

	//   CLEAR FIELDS
	const clearField = () => {
		setFileName("");
		setFileSize("");
		setImageSelected(false);
	};

	//   HANDLING IMAGE DROP
	const handleFrontDrop = (files, rejectedFiles) => {
		setError("");
		const file = files[0];
		const type = file.type.split("/")[0];

		if (files && files.length > 0) {
			if (type !== "image") {
				setValidity(false);
				setImageSelected(true);
				setErrorMessage("This file format is not suported");
				setError("File format is not supported.");
			} else if (file.size > 10 * 1048576) {
				setValidity(false);
				setImageSelected(true);
				setErrorMessage("Image size is to large !");
				setError("Image size should be less than 10 MB.");
			} else {
				setValidity(true);
				setFileName(stringProcess(file.name));
				setFileSize(fileSizeCalculation(file.size));
				setImageSelected(true);
				// setPreview(URL.createObjectURL(file));
				setFile(file);
			}
		}
	};

	return (
		<>
			<label>{label}</label>
			{imageSelected ? (
				<>
					{/* Preview */}
					{/* <div className={style.previewImage}>
            <img src={preview && preview} alt="" />
          </div> */}
					{/* File information */}
					<div
						className={
							validity
								? style.imageData
								: `${style.imageError} ${style.imageData}`
						}
					>
						<div
							className={
								validity
									? style.imageData__name
									: `${style.imageError__name} ${style.imageData__name}`
							}
						>
							{/* logo */}
							<div className={style.logo}>
								<BsFillImageFill />
							</div>
							{/* name */}
							<div className={style.name}>
								<h5>{validity ? fileName : errorMessage}</h5>
								<p>{fileSize}</p>
							</div>
						</div>

						<div
							className={
								validity
									? style.imageData__progress
									: `${style.imageData__progress} ${style.imageError__progress}`
							}
						>
							<div className={style.progress}></div>
							<div className={style.status}>
								{validity ? <BsFillCheckCircleFill /> : <FiXCircle />}
							</div>
						</div>

						<div className={style.deleteButton} onClick={clearField}>
							<BsTrash />
						</div>
					</div>
				</>
			) : (
				<Dropzone onDrop={handleFrontDrop} multiple={false}>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps()} className={style.inputField}>
							<input {...getInputProps()} />
							<div className={style.notUploadedBlock}>
								<div className={style.logo}>
									<BsCloudUpload />
								</div>
								<p>Drag and drop here a clear picture of your</p>
								<p>
									<strong>{label}</strong> or click here to upload
								</p>
							</div>
						</div>
					)}
				</Dropzone>
			)}
		</>
	);
}

export default NidField;
