import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function AuthGuard({ logged, children, roleActive }) {
  const user = useSelector((state) => state.user);
  const role = user.currentUser && user.currentUser.user_role;
  const navigate = useNavigate();

  useEffect(() => {
    // redirect to last page if (user is already logged in) & (we want to redirect if logged in)
    if (user.isLogedIn && logged === "yes") {
      navigate(-1);
    }
    // redirect to last page if (user not logged in) & (we want to redirect if user is not logged in)
    else if (!user.isLogedIn && logged === "no") {
      navigate("/login");
    }
    // redirect to login if user role is (not admin or super admin)
    else if(roleActive && user.isLogedIn && role === 3 ){
      navigate(-1);
    }
  }, [logged, navigate, user.isLogedIn, role, roleActive]);

  return <>{children}</>;
}

export default AuthGuard;
