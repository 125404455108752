import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import axios from "axios";
// Assets
import { API } from "../../Assets/API";
import style from "./TransactionTable.module.scss";
import find from "../../Assets/Profile/find.png";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
// import Data from "./Data.json";
// Components
import TableData from "./TableData";
import TableDataLoading from "../TransactionTableForAdmin/TableDataLoading";

function TransactionTable() {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.user);
	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(0);

	// handle pagination
	const handlePageClick = (event) => {
		setCurrentPage(event.selected + 1);
	};

	// Filter data when any transaction cancelled
	const filterTransaction = (id) => {
		let filteredData = [];

		data.forEach((d) => {
			let currentData = [];
			for (let i = 0; i < d.transactions.length; i++) {
				if (d.transactions[i].id === id) continue;
				currentData.push(d.transactions[i]);
			}
			filteredData.push({ date: d.date, transactions: currentData });
		});
		setData(filteredData);
	};

	// Data process for representation
	const processData = (Data) => {
		let finaldata = [];
		let curdata = [];
		let i = 0;

		for (i = 0; i < Data.length; i++) {
			if (i === 0) {
				curdata = [...curdata, Data[i]];
				continue;
			}

			const curday = parseInt(Data[i].date_time.split("-")[2]);
			const prevday = parseInt(Data[i - 1].date_time.split("-")[2]);

			if (curday === prevday) {
				curdata = [...curdata, Data[i]];
			} else {
				let obj = {
					date: Data[i - 1].date_time,
					transactions: curdata,
				};
				finaldata = [...finaldata, obj];
				curdata = [Data[i]];
			}
		}

		let obj = {
			date: Data[i - 1].date_time,
			transactions: curdata,
		};

		finaldata = [...finaldata, obj];
		setData([...finaldata]);
	};

	// Data fetching
	useEffect(() => {
		setLoading(true);
		if (user && user.currentUser) {
			axios
				.get(`${API.getMyTransactions}?page=${currentPage}`)
				.then((res) => {
					setLoading(false);
					processData(res.data.data.data);
					setLastPage(res.data.data.last_page);
				})
				.catch(() => {
					setLoading(false);
				});
		}
	}, [user, currentPage]);

	return (
		<>
			<div className={style.ttable}>
				{loading ? (
					<div className={style.table}>
						{Array(20)
							.fill("")
							.map(() => (
								<TableDataLoading key={Math.random()} />
							))}
					</div>
				) : data.length > 0 ? (
					data.map((d) => (
						<div className={style.tableContent} key={d.id}>
							<TableData
								date={d.date}
								transaction={d.transactions}
								filterTransaction={filterTransaction}
							/>
						</div>
					))
				) : (
					<div className={style.emptyTable}>
						<div>
							<img src={find} alt="" />
							<h4>Not Found !</h4>
							<p>
								You haven't made any transactions yet To make your first
								transaction <Link to="/">click here</Link>
							</p>
						</div>
					</div>
				)}
			</div>

			{data.length > 0 ? (
				<ReactPaginate
					breakLabel={window.innerWidth >= 700 ? "..." : ""}
					nextLabel={<BsChevronRight />}
					previousLabel={<BsChevronLeft />}
					onPageChange={handlePageClick}
					pageRangeDisplayed={window.innerWidth >= 700 ? 5 : 2}
					pageCount={lastPage}
					renderOnZeroPageCount={null}
					containerClassName={style.paginationBttns}
					previousLinkClassName={style.previousBtn}
					nextLinkClassName={style.nextsBtn}
					disabledClassName={style.paginationDisabled}
					activeClassName={style.paginationActive}
				/>
			) : null}
		</>
	);
}

export default TransactionTable;
