import style from "./TransactionTableForAdmin.module.scss";
import TableData from "./TableData";

function TransactionTableForAdmin({ pageRef, data }) {
	return (
		<>
			<div className={style.head}>
				<div className={style.id}>Id</div>
				<div className={style.username}>User</div>
				<div className={style.sent}>Sent</div>
				<div className={style.received}>Received</div>
				<div className={style.amount}>Sent Amount</div>
				<div className={style.amount}>Received Amount</div>
				<div className={style.status}>Status</div>
			</div>
			{data &&
				data.map((d) => <TableData key={d.id} info={d} pageRef={pageRef} />)}
		</>
	);
}

export default TransactionTableForAdmin;
