import { useState } from "react";
// Assets
import style from "./UserTable.module.scss";
import { FiLink } from "react-icons/fi";
import { BsFillCircleFill } from "react-icons/bs";
// Componets
import UserDetail from "./UserDetail";

function UserData({ info }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={style.data} onClick={() => setOpen(true)}>
        <div className={style.id}><FiLink /> {info.user_id}</div>
        <div className={style.name}>{info.user_name}</div>
        <div className={style.email}>{info.user_email}</div>
        <div className={style.phone}>{info.user_number}</div>
        <div className={style.status}>
          <div className={style.status__color}>
            <div
              className={
                info.status === "pending"
                  ? `${style.orange}`
                  : info.status === "incomplete"
                  ? `${style.violet}`
                  : info.status === "rejected"
                  ? `${style.red}`
                  : `${style.green}`
              }
            >
              <BsFillCircleFill />
            </div>
          </div>
          <div className={style.status__text}>{info.status}</div>
        </div>
        <div className={style.role}>
          <p
            className={
              info.user_role === 1
                ? style.superAdmin
                : info.user_role === 2
                ? style.admin
                : style.customer
            }
          >
            {info.user_role === 1
              ? "Super Admin"
              : info.user_role === 2
              ? "Admin"
              : "Customer"}
          </p>
        </div>
      </div>

      {open ? <UserDetail data={info} setOpen={setOpen} /> : null}
    </>
  );
}

export default UserData;
