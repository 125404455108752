import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import style from "./Terms.module.scss";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Terms() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Helmet>
				<title>Dollar Buy Sell Corner</title>
				<meta name="title" content="Terms | Dollar Buy Sell Corner" />
				<meta
					name="description"
					content="Trusted Dollar Buy Sell Site Dollar Buy Sell Corner. You can buy sell and exchange all kinds of dollars online from our site from WebMoney to Perfect Money to PayPal to Payeer to Skrill."
				/>
				<meta
					name="keywords"
					content="dollar buy sell, dollar exchange, dollar exchange bd, dollar buy sell bd, dollar buy sell bangladesh, dollar sell bd, skrill dollar buy sell bd, usd buy sell, dollar buy sell website, dollar buy sell in bangladesh"
				/>
			</Helmet>

			<Navbar />
			<div className={style.terms}>
				<div className={style.terms__container}>
					<h1>
						Terms <span>& Conditions</span>
					</h1>
					<p className={style.headingText}>
						We will always do our best to meet your needs and expectations. At
						the same time it's important to have things written down, so we can
						come to a mutual understanding before we start. And don't worry, you
						won't find much technical legal jargon here; rather, you'll find
						phrases that anyone can comprehend.
					</p>
					<p className={style.headingTextB}>
						আপনার চাহিদা এবং প্রত্যাশা পূরণের জন্য আমরা সর্বদা আমাদের যথাসাধ্য
						চেষ্টা করব। একই সাথে জিনিসগুলি লিখে রাখা গুরুত্বপূর্ণ, যাতে আমরা
						dollar buy sell শুরু করার আগে একটি পারস্পরিক বোঝাপড়ায় আসতে পারি।
					</p>

					{/* Block - Services & Charges */}
					<div className={style.block}>
						<h2>Services & Charge</h2>
						<p>
							<span className={style.numb}>1. </span>We render the services of
							an exchange of electronic payment systems units (digital
							currencies, e-currencies) between different payment systems.
							<span>
								- আমরা বিভিন্ন পেমেন্ট সিস্টেমের মধ্যে ইলেকট্রনিক পেমেন্ট
								সিস্টেম ইউনিট (ডিজিটাল মুদ্রা, ই-কারেন্সি) বিনিময়ের জন্য ডলার
								ক্রয় বিক্রয় পরিষেবা প্রদান করি
							</span>
						</p>
						<p>
							<span className={style.numb}>2. </span>Before each transaction,
							please take a look at our rate, reserve and terms and conditions.
							Otherwise, we will not be responsible if there are any issues
							related to the transaction.
							<span>
								- প্রতিটি ডলার ক্রয় বিক্রয় লেনদেন করার আগে, অনুগ্রহ করে আমাদের
								রেট, রিজার্ভ এবং শর্তাবলী দেখে নিন। অন্যথায়, লেনদেনের সাথে
								সম্পর্কিত কোনো সমস্যা থাকলে আমরা দায়ী থাকব না।
							</span>
						</p>
						<p>
							<span className={style.numb}>3. </span>Prices are always liable to
							change. Check the price while ordering.
							<span>
								- দাম সবসময় পরিবর্তনশীল। সুতরাং অর্ডার করার পূর্বে মূল্য চেক
								করুন।
							</span>
						</p>
						<p>
							<span className={style.numb}>4. </span>We do not intend for you to
							use our services in any countries where doing so would be against
							the law, and we do not provide them there. The User shall bear
							full responsibility for adhering to applicable local laws.
							<span>
								- আমরা কোনো ইউজারকে তার দেশের নিয়মকানুন ও আইনের বিরুদ্ধে যেয়ে
								আমাদের সেবা দিতে আগ্রহী নই। এজন্য আমাদের সেবাগুলো পেতে হলে
								প্রত্যেক ইউজারকে অবশ্যই নিজ দেশের আইন কানুন সম্পর্কে জেনে নিতে
								হবে।
							</span>
						</p>
						<p>
							<span className={style.numb}>5. </span>We do not accept any third
							party payments. If we find out that the funds come from a third
							party, we will not accept the payment and will immediately reject
							your order. The full amount of third party payments will be
							refunded less the transaction fees of e-currency processors.
							<span>
								- আমরা কোন তৃতীয় পক্ষের ডলার ক্রয় বিক্রয় পেমেন্ট গ্রহণ করি
								না। যদি আমরা জানতে পারি যে তহবিলগুলি তৃতীয় পক্ষ থেকে এসেছে,
								আমরা অর্থপ্রদান গ্রহণ করব না এবং অবিলম্বে আপনার অর্ডার
								প্রত্যাখ্যান করব। তৃতীয় পক্ষের অর্থপ্রদানের সম্পূর্ণ পরিমাণ
								ই-কারেন্সি প্রসেসরের লেনদেন ফি কম ফেরত দেওয়া হবে।
							</span>
						</p>
						<p>
							<span className={style.numb}>6. </span>If we have any doubts,
							including arbitrary ones, we reserve the right to refund the
							Customer's cash without providing a justification.
							<span>
								- যদি আমরা কোনো লেনদেনের সাথে সম্পর্কীত ইউজারের দেয়া তথ্য
								সম্পর্কে সন্দেহজনক কিছু পাই। তবে কোনো রকম জবাবদিহি প্রদান না
								করেই গ্রাহকের লেনদেন বাতিল করা হবে ও তার অর্থ ফেরত দেওয়া হবে।
							</span>
						</p>
						<p>
							<span className={style.numb}>7. </span>Payment must be made with
							the exact amount specified in the user's order. If the user remits
							more or less than the amount stated in the order, we will adjust
							the user’s order to the exact amount of funds received without
							prior notification.
							<span>
								- ব্যবহারকারীর ডলার ক্রয় বিক্রয় অর্ডার নির্দিষ্ট পরিমাণের সাথে
								অর্থপ্রদান করতে হবে। যদি ব্যবহারকারী অর্ডারে উল্লিখিত পরিমাণের
								চেয়ে বেশি বা কম পাঠায়, তাহলে আমরা পূর্বে বিজ্ঞপ্তি ছাড়াই
								লেনদেনের পরিমানকে ঐ নির্দিষ্ট পরিমানে করে নিয়ে ট্রানজেকশন গ্রহন
								করতে করতে বাধ্য হবো।
							</span>
						</p>
						<p>
							<span className={style.numb}>8. </span>If a user requests a refund
							for no apparent reason, we will remove 5% from your order total.
							<span>
								- যদি কোনও ব্যবহারকারী কোনও কারণ ছাড়াই ট্রানজেকশন পূর্ন হবার পর
								ফেরতের অনুরোধ করে, আমরা সে ট্রানজেকশন বা অর্ডারের মোট পরিমান
								থেকে 3% চার্জ করব।
							</span>
						</p>
					</div>

					<div className={style.block}>
						<h2>Support</h2>
						<p>
							<span className={style.numb}>1. </span>If you need any instant
							support or payment confirmation feel free to call us through whats
							app message/call messenger message or mail. You can find our
							contact details on the contact page and bottom of our site.
							<span>
								- যদি ইন্সট্যান্ট সাপোর্ট বা ট্রাঞ্জেকশনের কনফার্মেশন জানার
								প্রয়োজন হয় তবে নির্দ্বিধায় হোয়াটসএপ বা মেসেঞ্জারে কল অথবা আমাদের
								মেইলে মেইল করতে পারেন। আপনি আমাদের কন্টাক্ট ইনফর্মেশন আমাদের
								সাইটের নিচের দিকে পেয়ে যাবেন।
							</span>
						</p>
						<p>
							<span className={style.numb}>2. </span>In case of complaints
							regarding the completion of order, the client can contact
							administration of the service by email that is given in the
							contact page . The complaint shall be answered within no longer
							than 1 business day.
							<span>
								- একজন ক্ল্যানেটের যদি কোনো অর্ডার নিয়ে অভিযোগ থেকে থাকে তবে তা
								মেইলের মাধ্যমে আমাদের এডমিনিস্ট্রেটরদের জানাতে পারবে। দায়কৃত
								অভিযোগের উত্তর ১ কার্যদিবসের মধ্যে জানিয়ে দেয়া হবে।
							</span>
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Terms;
