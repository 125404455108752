import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./ProfileForm.module.scss";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import axios from "axios";
import { API } from "../../Assets/API";
import { getUserInfo } from "../../redux/user/userAction";

function ProfileForm() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrormessage] = useState({});
  const dispatch = useDispatch();

  // INITIAL VALUES
  const initVals = {
    name: user && user.currentUser ? `${user.currentUser.user_name}` : ``,
    email: user && user.currentUser ? `${user.currentUser.user_email}` : ``,
    number: user && user.currentUser ? `${user.currentUser.user_number}` : ``,
  };

  // VALIDATION SCHEMA
  const profileSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!").min(4, "Name must be 4 or more character long."),
    email: Yup.string().required("Name is required!"),
    number: Yup.string().required("Name is required!"),
  });

  //   ON SUBMIT HANDLER
  const updateProfile = (values) => {
    setLoading(true);
    setErrormessage(null);

    let data = {
      "name": values.name,
      "email": values.email,
      "number": values.number
    }

    axios.put(API.updateProfile, data)
    .then(() => {
      setLoading(false);
      toast.success("Profile updated successfully.");
      dispatch(getUserInfo);
    })
    .catch((err) => {
      setLoading(false);
      setErrormessage(err.response.data.errors);
    });
  };

  return (
    <div className={style.profileForm}>
      <Formik
        initialValues={initVals}
        validationSchema={profileSchema}
        onSubmit={(values) => updateProfile(values)}
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form>
            {/* ============ Name Fields ============ */}
            <div className={style.inputGroup}>
              <label htmlFor="name">Full Name</label>
              <Field
                name="name"
                placeholder="Enter your name"
                type="text"
                className={errors.name && touched.name ? style.haveerror : ``}
              />
              <small><ErrorMessage name="name" /></small>
            </div>

            {/* ============ Email Fields ============ */}
            <div className={style.inputGroup}>
              <label htmlFor="email">Email</label>
              <Field
                name="email"
                placeholder="Enter your email"
                type="email"
                className={( errorMessage && errorMessage.email ) || ( errors.email && touched.email) ? style.haveerror : ``}
              />
              <small>
                <ErrorMessage name="email" />
                {errorMessage && errorMessage.email && errorMessage.email[0]}
              </small>
            </div>

            {/* ============ number Fields ============ */}
            <div className={style.inputGroup}>
              <label htmlFor="phone">Phone Number</label>
              <Field
                name="number"
                placeholder="Enter your Phone number"
                type="text"
                className={(errorMessage && errorMessage.number ) || ( errors.number && touched.number ) ? style.haveerror : ``}
              />
              <small>
                <ErrorMessage name="number" />
                {errorMessage && errorMessage.number && errorMessage.number[0]}
              </small>
            </div>
            {/* Error Message */}
            <small className={style.errorMessage}></small>

            {/* ============ Submit Button ============ */}
            {
              loading 
              ? <button type="submit" disabled>Loading ...</button>
              : <button type="submit">Update Profile</button>
            }
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProfileForm;
