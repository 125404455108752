import { useState } from "react";
import { Helmet } from "react-helmet";
import style from "./Profile.module.scss";
import designImage from "../../../Assets/Profile/profile.png";
import doneImage from "../../../Assets/Profile/done.png";
// Components
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import ProfileForm from "../../../components/ProfileForm/ProfileForm";
import NidUploadOption from "../../../components/NidUploadOption/NidUploadOption";
import NidUploadForm from "../../../components/NidUploadForm/NidUploadForm";
import NidUploadInstruction from "../../../components/NidUploadInstruction/NidUploadInstruction";
import AuthGuard from "../../../components/AuthGuard/AuthGuard";
import PasswordResetForm from "../../../components/PasswordResetForm/PasswordResetForm";
import PopUp from "../../../components/PopUp/PopUp";

function Profile() {
	const [change, setChange] = useState(false);
	const [openNidForm, setOpenNidForm] = useState(false);
	const [popup, setPopup] = useState(false);

	return (
		<AuthGuard logged="no" roleActive={false}>
			<>
				<Helmet>
					<title>Profile | Dollar Buy Sell Corner</title>
					{/* <meta name="title" content="Profile | Dollar Buy Sell Corner" /> */}
				</Helmet>

				<Navbar />
				<div className={style.editProfile}>
					<h1>
						Hello <span>there!</span>
					</h1>
					<p>
						You are currently signed in, so you can start to make exchanges!
					</p>

					<div className={style.info}>
						<div className={style.info__form}>
							{/* form selection options */}
							{!openNidForm ? (
								<div className={style.options}>
									<div
										className={!change ? style.active : ``}
										onClick={() => setChange(false)}
									>
										Edit Profile
									</div>
									<div
										className={change ? style.active : ``}
										onClick={() => setChange(true)}
									>
										Change Password
									</div>
								</div>
							) : null}
							{/* selected form */}
							{openNidForm ? (
								<NidUploadForm setChange={setOpenNidForm} setPopup={setPopup} />
							) : !change ? (
								<ProfileForm />
							) : (
								<PasswordResetForm />
							)}
						</div>
						{/* notification section */}
						<div className={style.info__notify}>
							{openNidForm ? (
								<NidUploadInstruction />
							) : (
								<NidUploadOption setChange={setOpenNidForm} />
							)}
							{/* design */}
							<div className={style.design}>
								<img src={designImage} alt="" />
							</div>
						</div>
					</div>
				</div>
				<Footer />

				{popup ? (
					<PopUp
						image={doneImage}
						heading={`NID information uploaded!`}
						text={`Please wait untill you are fully verified by our admin.`}
						offPopUp={setPopup}
					/>
				) : null}
			</>
		</AuthGuard>
	);
}

export default Profile;
